import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "./config";

import tradsEN from "./translations/en";
import tradsFR from "./translations/fr";
import tradsPL from "./translations/pl";
import tradsPT from "./translations/pt";

const languageDetector = new LanguageDetector(null, {
  order: ["path"],
  lookupCookie: "lng",
  lookupLocalStorage: "lng",
});

export const resources = {
  languages: {
    en: "English",
    fr: "Français",
    pl: "Polski",
  },
  en: {
    translation: {
      ...tradsEN,
    },
  },
  fr: {
    translation: {
      ...tradsFR,
    },
  },
  pl: {
    translation: {
      ...tradsPL,
    },
  },
  pt: {
    translation: {
      ...tradsPT,
    },
  },
};

// Function used to display availables languages without current one
export const getNavbarTradLinks = (currentLng) => {
  return Object.keys(resources.languages).reduce((acc, key) => {
    if (key === currentLng) {
      return acc;
    }
    return {
      ...acc,
      [key]: resources.languages[key],
    };
  }, {});
};

export const findRouteKeyByValue = (
  object,
  value,
  parents = [],
  routes = []
) => {
  return Object.entries(object).reduce((acc, [key, path]) => {
    if (path === value) {
      return [...acc, ...parents, key];
    } else if (Object.prototype.toString.call(path) === "[object Object]") {
      return findRouteKeyByValue(path, value, [...parents, key], acc);
    }
    return acc;
  }, routes);
};

export const getTranslatedRoute = (t, i18n, path, currentLng) => {
  const routePath = path
    .replace(/^\/*(.+?)\/*?$/, "$1")
    .replace(new RegExp(`^/?(${AVAILABLE_LANGUAGES.join("|")})/?`), "");

  const keys = findRouteKeyByValue(
    i18n.store.data[i18n.language].translation.route,
    routePath
  );

  if (!keys.length) {
    return currentLng === DEFAULT_LANGUAGE ? "/" : `/${currentLng}`;
  }

  return `${currentLng === DEFAULT_LANGUAGE ? "/" : `/${currentLng}/`}${t(
    `route.${keys.join(".")}`,
    { lng: currentLng }
  )}`;
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    whitelist: AVAILABLE_LANGUAGES,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    load: "languageOnly",
  });

export default i18n;
