import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { Layout, HelmetCanonical } from '../../../components'
import { createClient } from 'contentful';
import { withTranslation } from 'react-i18next'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import './Tou.scss'

class Tou extends Component {
  state = {
    tou: null,
    title: null,
    updatedAt: null
  }

  componentDidMount() {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      environment: process.env.REACT_APP_CONTENTFUL_ENV
    });

    client
      .getContentType('termsOfUseSolution')
      .then(response => client.getEntries({
        locale: ['fr','en'].includes(this.props.i18n.language) ? this.props.i18n.language : 'en',
        content_type: response.sys.id
      }))
      .then(data => this.setState({
        tou: data.items[0].fields.rules,
        title: data.items[0].fields.title,
        updatedAt: data.items[0].sys.updatedAt
      }))
      .catch(console.error);
  }

  render() {
    const { t, i18n: { language: locale }, match: { routeKeys } } = this.props

    return (
      <Layout locale={locale}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.state.title}</title>
          <meta name="robots" content="index,follow"></meta>
          <html lang={locale} />
      </Helmet>

      <HelmetCanonical routes={routeKeys} />

      <main className="kz-tou">
          <h1>{this.state.title}</h1>
          <h2>{t('tou.last_change')}{new Date(this.state.updatedAt).toLocaleDateString(locale)}</h2>
        <section className="kz-tou--rules">
          {documentToReactComponents(this.state.tou)}
        </section>
      </main>
      </Layout>
    )
  }
}

export default withTranslation()(Tou)
