import React from 'react'
import { StickyContainer, Sticky } from 'react-sticky'
import cn from 'classnames'
import NavBar from '../NavBar'
import Footer from '../Footer'

import './Layout.scss'

  
const NavBarWithRef = React.forwardRef((props, ref) => (
  <NavBar innerRef={ref} {...props} />
))
class Layout extends React.Component {
  render() {
    const ref = React.createRef()

    return (
      <div className={cn('layout')}>
        <StickyContainer>
          <Sticky topOffset={53}>
            {({ style }) => (
              <NavBarWithRef style={style} ref={ref} />
            )}
          </Sticky>
          <div className={cn('content')}>
            {this.props.children}
          </div>
          <Footer />
        </StickyContainer>
      </div>
    )
  }
}

export default Layout
