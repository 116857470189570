import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import BusinessNav from "../../../components/BusinessNav";
import Icon from "../../../components/Icon";
import Trust from "../../../components/Trust";
import Furniture from "../../../components/Furniture";
import Prices from "../../../components/Prices";
import Discover from "../../../components/Discover";
import { HelmetCanonical, Layout } from "../../../components";
import CustomSlider from "../../../components/Slider";
import Examples from "../../../components/Examples";

import "./FurnitureDistributor.scss";
class FurnitureDistributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      read_more1: false,
    };
  }

  render() {
    const {
      t,
      i18n: { language },
      match: {
        routeKeys,
        params: { locale },
      },
    } = this.props;
    const prefix = locale ? `/${locale}` : "";
    const slides = [
      {
        defaultSrc: "/business/furniture/carousel-01.png",
        mobileSrc: "/business/furniture/carousel-01-mobile.png",
        alt: t("images.alt.furniture.carousel_01"),
      },
      {
        defaultSrc: "/business/furniture/carousel-02.png",
        mobileSrc: "/business/furniture/carousel-02-mobile.png",
        alt: t("images.alt.furniture.carousel_02"),
      },
      {
        defaultSrc: "/business/furniture/carousel-03.png",
        mobileSrc: "/business/furniture/carousel-03-mobile.png",
        alt: t("images.alt.furniture.carousel_03"),
      },
    ];

    const imagesExamples = [
      {
        url: "/furniture/1_SAM.jpg",
        translationKey: "furniture.sam_1",
        alt: t("images.alt.furniture.example_01"),
      },
      {
        url: "/furniture/2_SDB.jpg",
        translationKey: "furniture.sdb_2",
        alt: t("images.alt.furniture.example_02"),
      },
      {
        url: "/furniture/3_CUISINE.jpg",
        translationKey: "furniture.cuisine_3",
        alt: t("images.alt.furniture.example_03"),
      },
      {
        url: "/furniture/4_SALON.jpg",
        translationKey: "furniture.salon_4",
        alt: t("images.alt.furniture.example_04"),
      },
    ];

    return (
      <Layout locale={locale}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("seo.business.furniture_distributor.title")}</title>
          <meta
            name="description"
            content={t("seo.business.furniture_distributor.description")}
          ></meta>
          <meta name="robots" content="index,follow"></meta>
          <html lang={language} />
        </Helmet>

        <HelmetCanonical routes={routeKeys} />

        {/* Distributeur de mobilier (Furniture) */}
        <main className="kz-business">
          <section className="kz-business--intro">
            <BusinessNav />

            <Icon value="furniture" />

            <h1>
              <strong>
                {t("business.furniture_distributor.title")}
                <br />
                {t("business.furniture_distributor.title_2")}
              </strong>
              {t("business.furniture_distributor.title_3")}
            </h1>

            <Link
              to={`${prefix}/demo`}
              className="kz-button kz-button--tools kz-button--flat"
            >
              <span>{t("button.ask_demo_ext")}</span>
            </Link>
            <span>{t("business.furniture_distributor.desc_1")}</span>

            <CustomSlider slides={slides} />
          </section>

          <section className="kz-business--features">
            <div className="kz-business--container">
              <div className="kz-business--content kz-business--content-description">
                <img
                  src={
                    require("../../../assets/img/business/furniture/features-01-mobile.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.feature_01")}
                />
                <h2>{t("business.furniture_distributor.card_1_title")}</h2>
                <p>{t("business.furniture_distributor.card_1_desc_1")}</p>
                <strong>{t("button.try_3D")}</strong>
                <Link
                  to={`${prefix}/demo`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("button.ask_demo")}</span>
                </Link>
              </div>
              <div className="kz-business--content kz-business--content-visuel">
                <img
                  src={
                    require("../../../assets/img/business/furniture/features-01.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.feature_01")}
                />
              </div>
            </div>

            <div className="kz-business--container">
              <div className="kz-business--content kz-business--content-visuel">
                <img
                  src={
                    require("../../../assets/img/business/furniture/features-02.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.feature_02")}
                />
              </div>
              <div className="kz-business--content kz-business--content-description">
                <img
                  src={
                    require("../../../assets/img/business/furniture/features-02-mobile.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.feature_02")}
                />
                <h2>{t("business.furniture_distributor.card_2_title")}</h2>
                <p>
                  {t("business.furniture_distributor.card_2_desc_1")}
                  <br />
                  {t("business.furniture_distributor.card_2_desc_2")}
                  <br />
                  {t("business.furniture_distributor.card_2_desc_3")}
                </p>
                <strong>{t("button.try_3D")}</strong>
                <Link
                  to={`${prefix}/demo`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("button.ask_demo")}</span>
                </Link>
              </div>
            </div>

            <div className="kz-business--container">
              <div className="kz-business--content kz-business--content-description">
                <img
                  src={
                    require("../../../assets/img/business/furniture/features-03-mobile.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.feature_03")}
                />
                <h2>{t("business.furniture_distributor.card_3_title")}</h2>
                <p>
                  {t("business.furniture_distributor.card_3_desc_1")}
                  <br />
                  {t("business.furniture_distributor.card_3_desc_2")}
                  <br />
                </p>
                <Link
                  to={`${prefix}/demo`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("button.ask_demo")}</span>
                </Link>
              </div>
              <div className="kz-business--content kz-business--content-visuel">
                <img
                  src={
                    require("../../../assets/img/business/furniture/features-03.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.feature_03")}
                />
              </div>
            </div>

            <div className="kz-business--container">
              <div className="kz-business--content kz-business--content-visuel">
                <img
                  src={
                    require("../../../assets/img/business/furniture/features-04.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.feature_04")}
                />
              </div>
              <div
                className={`kz-business--content kz-business--content-description ${
                  this.state.read_more1 ? "kz-deployed" : null
                }`}
              >
                <img
                  src={
                    require("../../../assets/img/business/furniture/features-04-mobile.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.feature_04")}
                />
                <h2>{t("business.furniture_distributor.card_4_title")}</h2>
                <p>{t("business.furniture_distributor.card_4_desc_1")}</p>
                <p>
                  {t("business.furniture_distributor.card_4_desc_2")}
                  <span className="kz-business--content-hidden">
                    &nbsp;
                    {t("business.furniture_distributor.card_4_desc_3")}
                    <br />
                    <br />
                    {t("business.furniture_distributor.card_4_desc_4")}
                  </span>
                </p>
                <p
                  onClick={() => this.setState({ read_more1: true })}
                  className="kz-business--content-more"
                >
                  {t("business.furniture_distributor.read_more")}
                </p>
                <strong>{t("button.try_3D")}</strong>
                <Link
                  to={`${prefix}/demo`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("button.ask_demo")}</span>
                </Link>
              </div>
            </div>
          </section>

          <Examples
            title={"examples.furniture"}
            imagesExamples={imagesExamples}
          />

          <section className="kz-business--features kz-business--ecommerce">
            <div className="kz-business--container">
              <div className="kz-business--content kz-business--content-description">
                <img
                  src={
                    require("../../../assets/img/business/furniture/ecommerce-mobile.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.ecommerce")}
                />
                <h3>{t("business.furniture_distributor.retail.title")}</h3>
                <p>
                  {t("business.furniture_distributor.retail.desc_1")}
                  <br />
                  {t("business.furniture_distributor.retail.desc_2")}
                  <br />
                  {t("business.furniture_distributor.retail.desc_3")}
                </p>
                <Link
                  to={`${prefix}/demo`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("contact_us")}</span>
                </Link>
              </div>
              <div className="kz-business--content kz-business--content-visuel">
                <img
                  src={
                    require("../../../assets/img/business/furniture/ecommerce.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.furniture.ecommerce")}
                />
              </div>
            </div>
          </section>

          <Furniture />

          <Prices
            title={t("business.furniture_distributor.stand_out.title")}
            description={t("business.furniture_distributor.stand_out.desc")}
          />

          <Trust />

          <Discover />
        </main>
      </Layout>
    );
  }
}

export default withTranslation()(FurnitureDistributor);
