import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout, HelmetCanonical } from "../../components";

import "./About.scss";

const About = ({
  match: {
    routeKeys,
    params: { locale },
  },
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const prefix = locale ? `/${locale}` : "";

  return (
    <Layout locale={language}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("seo.about.title")}</title>
        <meta name="robots" content="index,follow"></meta>
        <html lang={language} />
      </Helmet>

      <HelmetCanonical routes={routeKeys} />

      <main className="kz-about">
        <section className="kz-about--intro">
          <h1>{t("about.title")}</h1>

          <div className="kz-about--description">
            <img
              src={require("../../assets/img/about/frenchtech.jpg").default}
              loading="lazy"
              alt={t("images.alt.about.frenchtech")}
            />
            <p>
              {t("about.desc_1")}
              <br />
              {t("about.desc_2")}
            </p>
          </div>

          <NavLink
            to={`${prefix}/${t("route.demo")}`}
            className="kz-button kz-button--tools kz-button--flat"
          >
            <span>{t("about.meetup_contact")}</span>
          </NavLink>
        </section>

        <section className="kz-about--press">
          <div className="kz-about--press-container">
            <h2>{t("about.press_release")}</h2>

            <a
              href="https://www.ladn.eu/news-business/actualites-startups/plan-3d-kozikaza-seduit-les-marques-de-deco/"
              target="__blank"
            >
              <img
                src={require("../../assets/img/about/press-ladn.png").default}
                loading="lazy"
                alt={t("images.alt.furniture.press_adn")}
              />
            </a>

            <a
              href="https://bfmbusiness.bfmtv.com/mediaplayer/video/focus-retail-kozikaza-com-la-marketplace-d-ameublement-en-3d-0512-1122829.html"
              target="__blank"
            >
              <img
                src={
                  require("../../assets/img/about/press-bfmbusiness.png")
                    .default
                }
                loading="lazy"
                alt={t("images.alt.furniture.press_bfm")}
              />
            </a>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default About;
