import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Icon from "../Icon";

import "./Discover.scss";

const Anchor = (props) => {
  return <a {...props}>{props.children}</a>;
};

const Discover = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Anchor id="kozikaza"></Anchor>
      <section
        id="kz-discover"
        className={
          props.description === "true"
            ? "kz-discover kz-discover--large"
            : "kz-discover kz-discover--small"
        }
      >
        <Icon value="kozikaza" />
        <h2>
          <Trans i18nKey="home.discover.title" />
        </h2>
        {props.description === "true" && (
          <p>{t("home.discover.description")}</p>
        )}
        <a
          href={t("kozikaza.link")}
          target="_blank"
          rel="noopener noreferrer"
          className="kz-button kz-button--white"
        >
          <span>
            <Icon value="plan-3d" />
            <Icon value="plan-3d-black" /> {t("button.discover")}
          </span>
        </a>
      </section>
    </>
  );
};

export default Discover;
