import React, { Component } from "react";
import Slider from "react-slick";

import "./Slider.scss";

class CustomSlider extends Component {
  render() {
    const settings = {
      dots: true,
      autoplay: true,
      autoplaySpeed: 10000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
    };
    return (
      <Slider {...settings}>
        {this.props.slides.map((slide, index) => (
          <picture key={index}>
            <source
              media="(max-width: 480px)"
              srcSet={require(`../../assets/img${slide.mobileSrc}`).default}
            />
            <img
              src={require(`../../assets/img${slide.defaultSrc}`).default}
              alt={slide.alt}
            />
          </picture>
        ))}
      </Slider>
    );
  }
}

export default CustomSlider;
