import React, { Component } from "react";

class Icon extends Component {
  render() {
    const { value } = this.props;
    return (
      <span className={`kz-icon kz-icon--${value}`}>
        <svg preserveAspectRatio="xMidYMid meet">
          <image
            xlinkHref={require(`../../assets/icons/${value}.svg`).default}
            width="100%"
            height="100%"
          />
        </svg>
      </span>
    );
  }
}

export default Icon;
