const translations = {
  images: {
    alt: {
      hp: {
        h_360d: "Fonction capture 360° en HD",
        h_3dhd: "Fonction capture HD",
        carousel_01: "Capture HD d'une maison vue de l'extérieur",
        carousel_02: "Aménagement d’un fauteuil sur le plan 3D",
        carousel_03: "Conception du terrain en 3D sur l’outil",
        example_01: "Rendu HD d’une salle de bain moderne noire et bois",
        example_02: "Rendu HD d’une maison avec garage et jardin",
        example_03: "Rendu HD d’une cuisine design blanche et noire",
        example_04: "Capture HD d’une salle à manger moderne",
        features_01:
          "Photo d’un utilisateur créant son plan en 2D sur Kazaplan",
        features_02: "Liste de meubles et objets disponibles sur l'outil",
        features_03: "Capture HD d’un salon moderne",
        video: "Vidéo tutoriel de l’utilisation du plan 3D",
      },
      furniture: {
        feature_01: "Aperçu du logo de la marque partenaire sur kazaplan",
        feature_02: "Présentation de la fonction ajouter au panier",
        feature_03:
          "Schéma d’acquisition des données saisies par les utilisateurs",
        feature_04: "Exemples de visuels HD réalisés sur l’outil plan 3D",
        ecommerce:
          "Affichage de la marque de l’enseigne partenaire sur l’objet présenté",
        carousel_01: "Rendu 3D HD d’un salon moderne",
        carousel_02:
          "Choix d’ameublement disponible dans le catalogue Kazaplan",
        carousel_03: "Aménagement d'un plan 3D sur l’outil kazaplan",
        example_01:
          "Plan 3D de maison plain-pied à l’architecture provençale avec garage",
        example_02: "Plan 2D d’une maison de 100m2 familiale",
        example_03: "Version 3D d’une maison familiale de 100m2",
        example_04:
          "Visuel 3D d’une maison de 100m2 avec un garage et un grand jardin",
      },
      home_builder: {
        feature_01: "Captures HD réalisées sur l’outil Kazaplan",
        feature_02:
          "Matériaux et éléments constructifs disponibles sur Kazaplan",
        feature_03: "Schéma de qualification des données saisies sur Kazaplan",
        carousel_01: "Rendu HD d’une maison individuelle",
        carousel_02: "Modélisation 3D d’une maison sur Kazaplan",
        carousel_03: "Galerie de modèles de plans en 3D",
        example_01: "Maison 3D avec un grand séjour moderne",
        example_02: "Plan de salle de bains noire et blanche moderne",
        example_03: "Visuel 3D d’une grande cuisine blanche et bois",
        example_04: "Salon moderne et lumineux bois blanc et gris             ",
      },
      material_distributor: {
        feature_01: "Conception d'un plan d'électricité sur Kazaplan",
        feature_02: "Exemples de captures HD réalisées sur Kazaplan",
        feature_03: "Tableau aperçu des surfaces sur l'outil 3D",
        carousel_01:
          "Aperçu du choix de brique et pierre disponible sur l’outil",
        carousel_02:
          "Fauteuils d’enseignes partenaires disponibles sur l’outil plan 3D",
        carousel_03: "Capture HD d’un plan maison en 3D",
        example_01: "Visuel 3D d’une maison d’architecte avec un grand jardin",
        example_02:
          "Schéma électrique 2D d’une maison réalisée à l’aide de notre configurateur de plan d’électricité",
        example_03: "Rendu 3D d'une salle de bain avec carreaux de ciment",
        example_04: "Plan 2D avec détails de surfaces",
      },
      about: {
        frenchtech: "Logo de la French tech",
        press_adn: "Article de presse du magasine L’ADN sur Kozikaza",
        press_bfm: "Promotion de Kozikaza sur le média BFM Business",
      },
      trust: {
        autourdebb: "Logo autour de bébé",
        home_builder: "Logo constructeurdemaison.net",
        lecubefrancais: "Logo Le Cube français",
        leroymerlin: "Logo Leroy Merlin",
        quotatis: "Logo Quotatis",
      },
    },
    examples: {
      hp: {
        salon_1:
          "Maison d’architecte conçue pour profiter à 100% de l’environnement. Le parquet au sol et les poutres en bois au plafond réchauffent l’intérieur noir et blanc de cette jolie maison moderne à la superbe hauteur sous plafond.",
        maison_2:
          "Plan de maison contemporaine originale offrant un garage et une grande terrasse en bois couverte. La façade en briques rouges et la toiture en ardoise sont valorisées par ce visuel 3D réaliste.",
        sdb_3:
          "Les matériaux et la décoration intérieure soignée mettent en valeur cette belle salle de bain design de 5m2. Une grande baignoire d’angle ainsi qu’un meuble double vasque et beaucoup de rangements en font une pièce fonctionnelle et familiale. ",
        sam_4:
          "Cuisine 3D au style moderne et épuré : on y retrouve des meubles blancs et un plan de travail noir ainsi qu’un grand coin repas. La pièce est baignée de lumière grâce à la large baie vitrée offrant une vue dégagée sur le jardin.",
      },
      furniture: {
        sam_1:
          "Belle maison 3D avec un séjour cathédrale très agréable et mettant en valeur l’aménagement intérieur : Mobilier et décoration modernes à l’inspiration scandinave tendance.",
        sdb_2:
          "Plan de Salle de bain dans les tons noir et bois. Devant la baignoire, avec une paroi de douche de type verrière, prend place un meuble double vasque surplombé d’un grand miroir en bois.",
        cuisine_3:
          "Visuel 3D d’une grande cuisine équipée aux couleurs claires avec des meubles blancs et un plan de travail en bois. L’aménagement avec les petits éléments électroménagers et la déco rendent la scène réaliste.",
        salon_4:
          "Salon / salle à manger moderne aux dominantes de bois, blanc et gris. Il est aménagé et décoré dans les moindres détails. Le réalisme est tel qu’on confond la photo 3D avec une vraie photo.",
      },
      constructor: {
        maison_1:
          "Plan 3D de maison plain-pied à l’architecture provençale de 90m2 avec garage. La façade réalisée en enduit gratté fin et les tuiles canal en terre cuite s’associent parfaitement à la pergola en bois de la terrasse couverte.",
        plan_2:
          "Plan 2D d’une maison de 100m2 originale et familiale. Les deux salles de bain et la cuisine ouverte sur le grand salon lumineux en font une habitation parfaite pour une famille avec un ou deux enfants.",
        vueciel_3:
          "Version 3D de cette maison familiale de 100m2. L’architecture intérieure qui a été réalisée avec du mobilier et de la décoration tendance mettent en valeur la maison et permettent de s’y projeter facilement.",
        maison_4:
          "Maison de 100m2 avec un étage sous combles, un garage et un grand jardin. L’extérieur est aménagé en 3D avec une jolie terrasse en bois, un cabanon de jardin et des jeux pour enfants… on s’y croirait. ",
      },
      material: {
        ext_1:
          "Belle maison d’architecte permettant de profiter de l’environnement extérieur verdoyant. La façade en bardage composite et la terrasse extérieure couverte par un auvent en bardage bois en font une maison unique.",
        elec_2:
          "Schéma électrique 2D d’une maison réalisée à l’aide de notre configurateur de plan d’électricité. On y retrouve tous les éléments électriques : prises, interrupteurs, points lumineux, etc. . Sur ce plan les liaisons par câbles électriques sont également représentées.",
        sdb_3:
          "Rendu 3D d’une salle de bain avec du parquet à joint pont de bateau. Sur l’estrade de la baignoire, le calepinage du carrelage et de la faïence est travaillé dans un style rétro des plus chics.",
        plan_4:
          "Sur tous les plans 2D, on retrouve les cotes détaillées de chaque pièce. Un moyen simple d’obtenir les surfaces et les mesures précises de toute la maison et ses extérieurs.",
      },
    },
  },
  seo: {
    home: {
      title:
        "Logiciel de plan de maison 2D 3D professionnel en ligne - Kazaplan.com",
      description:
        "Kazaplan est un logiciel de plan 2D 3D professionnel qui permet de modéliser maisons et appartements en quelques clics. Kazaplan dope votre business de construction de maisons, de distribution de mobilier et matériaux.",
    },
    business: {
      furniture_distributor: {
        title:
          "Réalité virtuelle et 3D pour vente de mobilier et décoration - Kazaplan.com",
        description:
          "Boostez le tunnel commercial et Augmentez votre montant moyen commande grâce à la 3D en ligne.",
      },
      material_distributor: {
        title:
          "Configurateur de vente 3D pour distributeurs de matériaux - Kazaplan.com",
        description:
          "Améliorez les ventes de vos matériaux grâce à Kazaplan le configurateur de vente 3D. Configurateur 2D/3D avec les règles métiers",
      },
      home_builder: {
        title:
          "Logiciel d'architecture 3D pour les constructeurs de maisons individuelles - Kazaplan.com",
        description:
          "Le logiciel d’architecture 3D professionnel Kazaplan vous accompagne aux différentes étapes de la vente de maisons grâce à une solution complète de conception 2D et 3D.",
      },
    },
    pricing: {
      title: "Tarifs - Kazaplan.com",
    },
    about: {
      title: "A propos de nous - Kazaplan.com",
    },
    contact: {
      title: "Demandez une démo - Kazaplan.com",
    },
  },
  route: {
    "individual-home-builders": "constructeur-de-maisons-individuelles",
    "furniture-distributor": "distributeur-mobilier",
    "building-material-distributor": "distributeur-de-materiaux",
    tool: {
      "privacy-policy": "solution/donnees-personnelles",
      "terms-of-use": "solution/conditions-utilisation",
      "terms-of-sales": "solution/conditions-generales-de-vente",
    },
    website: {
      "privacy-policy": "site/donnees-personnelles",
      "terms-of-use": "site/conditions-utilisation",
      "terms-of-sales": "site/conditions-generales-de-vente",
    },
    demo: "demo",
    "legal-notice": "mentions-legales",
    pricing: "tarifs",
    api: "documentation-api",
    about: "qui-sommes-nous",
    backup: "recuperer-mes-plans",
  },
  button: {
    ask_demo: "Demander une démo",
    ask_demo_ext: "Demandez une démo de Kazaplan",
    personnal_user: "Je suis un particulier",
    explore_solution: "Découvrir la solution",
    pricing: "Voir les tarifs",
    discover: "Découvrez Kozikaza",
    try_3D: "Vous voulez une démo du logiciel de conception 3D\xA0?",
  },
  labels: {
    "360_views": "Format 360°",
    hd_image: "Visuel HD",
  },
  navbar: {
    menu: {
      home: "Accueil",
      business: {
        home: "Pour votre business",
        home_builder: "Constructeur de maisons individuelles",
        furniture_distributor: "Distributeur de mobilier",
        material_distributor: "Distributeur de matériaux, GSB",
      },
      pricing: "Tarifs",
    },
  },
  footer: {
    api: "Documentation API",
    about: "Qui sommes nous",
    tou: "CGU",
    tos: "CGV",
    privacy: "Données personnelles",
    legal: "Mentions légales",
    follow: "Suivez-nous",
    kazaplan_solutions: "Solutions Kazaplan",
    kazaplan_website: "Site kazaplan.com",
  },
  home: {
    kzp: "Kazaplan",
    title: "Le logiciel de plan de maison 3D professionnel",
    title_2: "qui dope votre business directement sur votre site",
    kzpintro:
      "Kazaplan est un logiciel de plan 3D professionnel qui permet de modéliser maisons et appartements en quelques clics.",
    feature_1_head: "Très performant",
    feature_2_head: "Facile à utiliser",
    feature_3_head: "100% en ligne",
    feature_1:
      "Rapide et complet, il permet de <strong>créer un plan de maison en 3D</strong> rapidement",
    feature_2:
      "Pas besoin d'être <strong>architecte</strong>, l'<strong>outil 3D</strong> est conçu pour tous publics",
    feature_3:
      "Pas besoin de télécharger le <strong>logiciel</strong>, les plans sont accessibles via votre navigateur internet",
    backup: {
      title: "Vous ne trouvez plus vos plans ?",
      click: "Cliquez-ici",
      askme: `Ne plus me demander`,
    },
    constructor: {
      title: "Constructeur de maisons individuelles",
      description: `<strong>Valorisez votre offre, captez vos futurs clients et aidez vos commerciaux à vendre vos maisons grâce à la 3D !</strong>
        Un projet de construction est un projet de vie avant tout. Mettez toutes les chances de votre côté avec un outil d’aide à la vente bien pensé. 
        Que vous proposiez des maisons sur mesure ou que vous ayez un catalogue de maisons existant, la création de plans avec Kazaplan vous aidera. Lotissement, maison contemporaine, traditionnelle, maison à toit plat, plain-pied ou maison bois... Kazaplan vous permet de dessiner chaque modèle de maison en 3D !`,
    },
    decoration_distributor: {
      title: "Distributeurs de mobilier et de décoration",
      description: `<strong>Vendez vos meubles grâce à la réalité virtuelle directement sur votre site.</strong>
        Dans le cadre d’un projet d’aménagement d’intérieur, vos clients ont besoin de se projeter et d’imaginer facilement vos produits dans leur future déco.
        Avec Kazaplan, ils pourront créer leur maison virtuelle et faire leur choix sereinement concernant l’agencement de chaque pièce. La 3D leur permettra de se rendre compte des dimensions, des textures mais aussi des couleurs de chaque meuble, luminaire et objet de décoration. Ils se projettent\xA0!`,
    },
    material_distributor: {
      title: "Distributeurs de matériaux, GSB",
      description: `<strong>Valorisez votre offre et vendez vos matériaux avec des configurateurs sur mesure intégrés à votre site.</strong>
        Dans le cadre d'un projet de construction ou rénovation, vos clients ont besoin de se projeter et d’estimer le coût des travaux afin de choisir leurs matériaux. Des visuels attrayants mettront en valeur vos produits.
        Un configurateur 2D ou 3D intégrant vos règles métiers aidera vos clients de se projeter, quelle que soit votre activité. Avec Kazaplan tout est possible\xA0: créer une cuisine 3D ou une salle de bain 3D, représenter le réseau électrique ou encore simuler la pose de revêtements de mur ou sol.`,
    },
    plan2d: {
      title: "Dessiner un plan en 2D",
      description: `Avec notre Logiciel 3D rapide et intuitif, il vous faudra quelques minutes pour concevoir le plan 2D d’une pièce ou d’une maison complète.
        Commencez par dessiner facilement vos murs et cloisons, représentez vos différents étages, vous pourrez alors placer vos portes, fenêtres et escaliers ou encore tracer vos sous-pentes en quelques clics.<br /><br/>
        Fini les logiciels d’architecture 3D complexes réservés aux bureaux d’études, optez pour le logiciel pour plan de maison Kazaplan\xA0: un outil 3D pensé pour tous.`,
      want_it: "Vous voulez une démo du logiciel<br />d'architecture\xA0?",
    },
    plan3d: {
      title: "Aménager et décorer le plan en 3D",
      description: `Imaginez l’aménagement intérieur de la maison en intégrant votre propre collection de mobilier et d’objets déco modélisés en 3D.<br />Piochez aussi dans notre catalogue de plus de 2500 objets 3D constamment enrichi pour meubler et décorer intérieur et extérieur virtuellement\xA0: meubles contemporains, déco intérieure tendance, équipements ou encore électroménager.`,
      want_it: "Vous voulez une démo du<br />logiciel d'aménagement 3D\xA0?",
    },
    planHD: {
      title: "Passer de la maquette 3D au rendu réaliste en haute définition",
      desc_1:
        "En quelques minutes votre client ou votre collaborateur reçoit le rendu 3D HD ou format 360° de sa pièce dans sa boite mail\xA0!",
      desc_2: `Après la modélisation 3D, il suffit de quelques clics pour générer une image 3D tellement réaliste qu’elle ressemble à une photo. Et après\xA0? Passez à la visite virtuelle de la maison\xA0: c’est comme si vous y étiez. Rien de tel pour mettre en avant votre offre, inspirer et aider vos clients à se projeter dans leur future maison\xA0!`,
      want_it: "Vous voulez une démo du<br />logiciel de conception 3D\xA0?",
    },
    examples: {
      title:
        "Quelques modèles de visuels réalistes tirés de plans 3D faits avec le logiciel maison 3D Kazaplan",
      more_information: "Plus d'informations",
    },
    prices: {
      title:
        "Que votre activité consiste en la construction de maisons individuelles, en la distribution de mobilier ou en la distribution de matériaux de bricolage, notre logiciel de modélisation 3D vous aidera et s'intégrera parfaitement à vos outils professionnels.",
      description:
        "La conception de plans 2D 3D, les configurateurs métiers, la visite 3D ou encore les visuels haute définition mettant en valeur votre offre produits sont des armes redoutables pour vous différencier de la concurrence. N'attendez plus, et demandez une démo du Kazaplan\xA0: un des meilleurs outils de dessin 3D, simple d'utilisation et entièrement en ligne. Il est conçu pour vous, les professionnels du bâtiment et de l'aménagement intérieur.",
    },
    discover: {
      title:
        "<strong>Vous êtes un particulier</strong> ou <strong>architecte d’intérieur</strong>\xA0?",
      description:
        "Rdv sur Kozikaza.com où vous retrouverez le logiciel de plan de maison 3D Kazaplan disponible gratuitement ainsi qu’une communauté de particuliers ayant un projet de construction de maison, de rénovation ou de décoration. Plus d’un million de plans sont faits chaque année sur Kozikaza.com",
    },
  },
  business: {
    home_builder: {
      title: "Kazaplan, le Logiciel d'architecture 3D",
      title_2: "pour les constructeurs de maisons individuelles",
      sell_more: "vendez plus de maisons\xA0!",
      desc_1: "En ligne - Intuitif -  Sans engagement",
      desc_2:
        "Le logiciel d’architecture 3D professionnel Kazaplan vous accompagne aux différentes étapes de la vente de maisons grâce à une solution complète de conception 2D et 3D.",
      check_1: "Mettez en avant votre catalogue de maisons ",
      check_2:
        "Proposez un outil d’aide à la vente à votre équipe commerciale ",
      check_3:
        "Captez plus de prospects ayant un projet de construction de maison.",
      card_1_title:
        "VALORISEZ VOS MAISONS ET AUGMENTEZ VOTRE TAUX DE TRANSFORMATION\xA0!",
      card_1_desc_1:
        "Présentez vos avant-projets à vos prospects à l’aide de visuels 3D réalistes ou 360°. Montrez-leur des maisons aménagées avec du mobilier contemporain et une déco intérieure tendance, puis proposez-leur une visite virtuelle de la maison. Chaque pièce peut ainsi être visitée, comme si vos clients y étaient.",
      card_1_desc_2:
        "Tous ces supports obtenus à partir de notre logiciel de construction de maison vous aideront à mettre en valeur les projets. Les images 3D au rendu haute définition sublimeront vos maisons et la visite virtuelle 3D aidera les futurs acquéreurs à se projeter.",
      card_2_title: "ACCÉLÉREZ LA PHASE D’AVANT-PROJETS",
      card_2_desc_1: "Vous disposez d’un catalogue de maisons\xA0?",
      card_2_desc_2:
        "Modélisez vos maisons en 2D et 3D. Puis laissez votre force commerciale adapter l’intérieur et l’extérieur des modèles aux besoins de leurs prospects, à l’aide de notre catalogue d’objets 3D\xA0: meubles, déco et revêtements. L’outil est alors un logiciel d’aménagement d’intérieur.",
      card_2_desc_3: "Vous réalisez des maisons sur-mesure\xA0?",
      card_2_desc_4:
        "Désengorgez votre bureau d’étude en équipant vos commerciaux d’un logiciel simple d’utilisation",
      card_2_desc_5:
        "permettant de dessiner un plan de maison en 3D de A à Z\xA0: après la conception architecturale, ils pourront aménager et décorer le plan en quelques minutes",
      card_2_desc_6:
        "Un plan 3D adapté permet de lever les freins ou de trancher rapidement entre deux solutions.",
      card_2_desc_7:
        "Le logiciel de modélisation 3D Kazaplan est la solution pour répondre à votre objectif\xA0: aider vos clients à se projeter dans leur futur intérieur grâce à l’aménagement 3D de la maison.",
      card_2_want_it:
        "Vous voulez une démo du logiciel de construction de maison\xA0?",
      card_3_title: "CAPTEZ LES COORDONNÉES DES VISITEURS DE VOTRE SITE",
      card_3_desc_1:
        "A la différence des logiciels d’architecture à télécharger tels Cedreo ou Miao, vous pourrez également laisser à vos clients la liberté d'utiliser notre logiciel de construction de maison 3D pour affiner leur envies.",
      card_3_desc_2:
        "Laissez les visiteurs de votre site dessiner facilement leur plan de maison en 3D ou aménager les modèles 3D de maisons de votre catalogue, directement sur votre site grâce à de nombreuses fonctionnalités avancées et paramétrables.",
      card_3_desc_3:
        "Avec le logiciel de construction 3D Kazaplan, vos prospects peuvent s’approprier virtuellement les",
      card_3_desc_4:
        "modèles de maisons que vous proposez\xA0: en quelques clics, ils peuvent agencer, meubler et décorer entièrement intérieur et extérieur. Ils utilisent l’outil comme un logiciel d’aménagement d’intérieur. Vos forces commerciales ont toutes les cartes en main pour les recontacter\xA0: style de maison, nombre de pièces, dimensions ou encore aménagement souhaités.",
      card_3_want_it: "Vous voulez une démo du logiciel de conception 3D\xA0?",
      business_examples:
        "Découvrez des exemples de visuels 3D réalistes obtenus via le logiciel de plan de maison 3D Kazaplan",
      stand_out: {
        title:
          "Professionnels de la construction de maisons individuelles\xA0: utilisez notre logiciel d’architecture 3D comme outil d’aide à la vente.",
        desc: "La modélisation et la visualisation de plans 2D/3D, la visite virtuelle ou encore les images photoréalistes mettra en avant votre offre de manière innovante. Démarquez-vous ainsi de vos concurrents\xA0! Demandez dès aujourd’hui une démo du Kazaplan\xA0: un des outils les plus avancés dans la conception 3D, facile à prendre en main, intuitif et sans téléchargement. Il est pensé pour vous, professionnels de la construction.",
      },
      more_information: "Plus d'informations",
      read_more: "Lire la suite",
    },
    furniture_distributor: {
      title: "KAZAPLAN, LA RÉALITÉ VIRTUELLE POUR VENDRE",
      title_2: "MOBILIER ET DECORATION EN LIGNE",
      title_3: "Pour les fournisseurs, les pure players et les distributeurs",
      desc_1:
        "Un logiciel 3D disponible sur votre site -  Une agence 3D à votre disposition - Sans engagement",
      card_1_title: " VOS MEUBLES DANS L'INTÉRIEUR 3D DE VOTRE CLIENT",
      card_1_desc_1:
        "En quelques minutes, les visiteurs de votre site internet vont pouvoir aménager leur intérieur en 3D avec votre catalogue 3D de meubles et décorations. Le Kazaplan est un outil d’aide à l’achat qui permettra à vos visiteurs d’imaginer vos produits dans leur intérieur en 3D.",
      card_1_want_it:
        "Vous voulez une démo du logiciel d’architecture d'intérieur 3D\xA0?",
      card_2_title:
        "AUGMENTEZ VOTRE MONTANT MOYEN COMMANDE (MMC) GRACE A LA 3D",
      card_2_desc_1: "Favorisez les achats groupés ou d’impulsion\xA0!",
      card_2_desc_2:
        "Grâce à votre catalogue de meubles 3D, vos futurs clients vont pouvoir associer différents produits de votre collection pour créer une ambiance au sein de leur intérieur virtuel. La visualisation 3D est un vrai levier de cross-selling.",
      card_2_desc_3:
        "Que vous utilisiez Magento, prestashop ou tout autre CMS, grâce à nos API, ils n’auront plus qu’un clic à faire pour pousser la liste des éléments du Kazaplan dans le panier de votre site e-commerce\xA0!",
      card_3_title:
        "CAPTEZ LES COORDONNÉES DES VISITEURS DE VOTRE SITE INTERNET",
      card_3_desc_1:
        "Grâce à Kazaplan, vos clients vont designer leur salon, leur cuisine, leur dressing ou encore leur chambre en 3D. C’est le moment parfait pour les recontacter et échanger sur leur projet\xA0! En plus, vous pourrez directement plugger Kazaplan à votre outil de CRM grâce à nos API et faire du lead nurturing.",
      card_3_desc_2:
        "Les clients aménagent leurs plans avec vos meubles > enregistrent leurs plans avec leurs coordonnées > lead nurturing",
      card_4_title:
        "PLONGEZ vos clients dans des showrooms virtuels avec les images 3D HD et 360°",
      card_4_desc_1:
        "Les images HD 3D sont tellement réalistes qu’elles ressemblent à de véritables photos. Et le mode 360° permet de visualiser entièrement la pièce, comme si on y était.",
      card_4_desc_2:
        "Créez des galeries de rendus réalistes pour sublimer vos produits. Créez différentes ambiances en 3D et économiser sur la production de visuels produits en studio photo\xA0! Notre studio 3D s’occupe entièrement de la modélisation de vos produits en objets 3D, à vous",
      card_4_desc_3: "ensuite de créer vos images d'ambiance.",
      card_4_desc_4:
        "Et pourquoi pas proposer ce service à vos clients\xA0? Laissez les modéliser leur pièce, puis générer des visuels 3D HD pour les faire rêver avec vos produits\xA0!",
      business_examples:
        "Découvrez quelques exemples de visuels HD et 360° de plans réalisés avec le logiciel de plan 3D Kazaplan",
      more_information: "Plus d'informations",
      read_more: "Lire la suite",
      retail: {
        title:
          "E-commerçants\xA0: Mettez en avant vos produits auprès de particuliers ayant un projet maison sur Kozikaza.com",
        desc_1:
          "+ de 80 000 plans / mois pour des projets de construction, rénovation ou d’aménagement / déco. Que vous vendiez du mobilier, de la déco ou des matériaux, trouvez une nouvelle source de trafic qualifié et de revenus avec le Kazaplan sur Kozikaza.com. ",
        desc_2:
          "Laissez notre agence 3D modéliser vos produits et mettre en avant votre marque dans le catalogue de meubles sur Kozikaza. En un clic, les visiteurs accèderont à la fiche produit correspondante sur votre site e-commerce. Ils n’ont plus qu’à mettre le produit dans leur panier et c’est vendu\xA0!",
        desc_3:
          "N’attendez plus, faites la promotion de vos produits sur le logiciel de plan maison 3D gratuit de Kozikaza.com\xA0!",
      },
      stand_out: {
        title: "Démarquez-vous sur le marché du meuble et de la déco",
        desc: "Vous l’aurez compris, le logiciel de conception 3D Kazaplan peut vous aider à vous démarquer de vos concurrents sur le marché de l'ameublement et de la décoration. Que ce soit pour proposer un outil de modélisation 3D avec votre catalogue en ligne ou pour réaliser des visuels d’ambiance produits, c’est un formidable outil d’aide à l’achat qui vous permettra d’augmenter votre chiffre d’affaires rapidement.",
      },
    },
    material_distributor: {
      title: " KAZAPLAN, CONFIGURATEUR DE VENTE DE MATÉRIAUX GRÂCE À LA 3D",
      title_2: "Pour les distributeurs de matériaux & enseignes de bricolage",
      visibility: "augmentez votre visibilité",
      desc_1: "En ligne - Intuitif - Sans engagement",
      card_1_title:
        "BOOSTEZ LA VENTE DE VOS MATÉRIAUX À L’AIDE D’UN CONFIGURATEUR",
      card_1_desc_1:
        "Pour vendre vos revêtements de sol ou de mur,  vos matériaux de construction, vos fournitures d’électricité... notre équipe développe des configurateurs 2D ou 3D avec vos règles métiers directement connectées à la puissance du Kazaplan.",
      card_1_desc_2: "Vous avez une équipe de développeurs\xA0?",
      card_1_desc_3:
        "Ils pourront eux même utiliser nos api pour développer votre propre configurateur 3D.",
      card_2_title:
        "VOUS AVEZ DES MATERIAUX DECORATIFS\xA0? LAISSEZ VOS CLIENTS SE PLONGER DANS DES SHOWROOMS 3D EN RÉALITÉ VIRTUELLE",
      card_2_desc_1:
        "Les images 3D sont si réalistes qu’elles permettent de remplacer les photos. Les visuels 360° permettent même d’aller plus loin et de visiter une pièce ou une maison entière en 3D afin de mieux se projeter.",
      card_2_desc_2:
        "Créez des galeries de visuels 3D réalistes pour sublimer vos matériaux et vos design. Vos produits seront entièrement modélisés par notre studio 3D, à vous ensuite de créer vos visuels d'ambiance. Vous pourrez créer facilement différents aménagements et styles déco grâce à notre large catalogue de modèles 3D. Cela vous permettra d’économiser sur la réalisation de photos en studio\xA0! ",
      card_2_desc_3:
        "Pour aller plus loin encore, laissez vos clients réaliser eux-mêmes ces visuels de haute qualité, sur leur propre plan 3D et avec vos produits\xA0: un véritable levier de vente.",
      card_3_title:
        "Récupérez LES SURFACES D’UNE PIÈCE OU D’UNE MAISON FACILEMENT",
      card_3_desc_1:
        "Vos clients vont tracer une pièce, ou designer leur maison et vous transmettre l’ensemble des données relatives à leur plan.",
      card_3_desc_2:
        "Grâce à nos API, récupérez facilement les surfaces nécessaires à la réalisation de votre estimation.",
      business_examples:
        "Retrouvez des exemples de visuels 3D réalistes réalisés avec l’outil 3D Kazaplan",
      stand_out: {
        title:
          "Distributeurs de matériaux et GSB\xA0: Optez pour des outils 2D et 3D adaptés à vos métiers\xA0!",
        desc: "Les configurateurs métiers, les plans 2D et 3D ou encore la réalisation d’images en haute définition mettront en valeur vos produits face à vos concurrents. Boostez ainsi vos ventes\xA0! Demandez une démo du Kazaplan\xA0: un des meilleurs outils de configuration 3D, facile à utiliser et sans téléchargement. Il est fait pour vous, professionnel du bâtiment.",
      },
      more_information: "Plus d'informations",
      read_more: "Lire la suite",
    },
  },
  pricing: {
    title:
      "Selon votre business et vos besoins, il y a forcément une formule Kazaplan cloud  qui est faite pour vous\xA0!",
    title_2: "Le logiciel 3D Kazaplan peut s’intégrer sur votre site et est",
    title_3: "Sans engagement de durée",
    pricing_nf:
      "Vous ne trouvez pas de plan tarifaire qui correspond à votre usage\xA0:",
    special_offer: "Demandez une offre personnalisée",
    offers: {
      version: "Version",
      include: "INCLUS\xA0:",
      interested: "J'en profite\xA0!",
      month: "/Mois",
      internal: {
        title: "ARCHITECTE INTERNAL",
        desc: "Vous souhaitez obtenir un logiciel facile d'utilisation pour présenter des projets d'appartement ou de maison en 2D et 3D pour vos équipes",
        include_1: "Jusqu'à 100 plans sauvegardés par mois",
        price: "299€",
      },
      all: {
        title: "ARCHITECTE COMPLETE",
        desc: "Vous souhaitez, en plus de la version architecte internal, donner la possibilité à vos clients de s'approprier vos maisons, appartements et capter leurs coordonnées sur un site internet",
        include_1: "Jusqu'à 500 plans sauvegardés par mois",
        include_2: "Captation de lead depuis votre site internet",
        price: "399€",
      },
      ecommerce: {
        title: "E-COMMERCE",
        desc: "Vous souhaitez permettre à vos clients de dessiner leur maison et d’y intégrer votre mobilier en 3D... Vendez à travers ce nouveau levier innovant\xA0!",
        include_1: "Jusqu'à 500 plans sauvegardés par mois",
        include_2: "Captation de lead depuis votre site internet",
        include_3: "Intégration de la shopping list",
        include_4: "Fonctionnalité de transmission à votre panier d’achat web",
        price: "990€",
      },
    },
    pricing_earnings: {
      title:
        "Quelque soit le plan tarifaire choisi, vous bénéficiez si vous le désirez\xA0:",
      check_1:
        "d'une bibliothèque de plus de 1000 meubles et revêtement contemporains,",
      check_2:
        "d'un accès au back office administrateur et 5 accès collaborateurs pour consulter les statistiques et administrer l'outil",
      check_3:
        "d'un centre d'aide tutoriel pour vous aider ou aider vos clients dans l'usage du Kazaplan",
    },
    options: {
      title: "Options complémentaires\xA0:",
      backup: {
        label:
          "Plan sauvegardé additionnel au volume inclus à votre forfait mensuel\xA0:",
        price: "0,50€\xA0HT/plan",
      },
      hd: {
        label: "Production de rendu HD\xA0:",
        price:
          "1€\xA0HT/HD (vous pouvez le facturer ou non à vos clients, et au prix que vous souhaitez",
      },
      view_360: {
        label: "Production de rendu 360\xA0:",
        price: "6€\xA0HT/ Rendu 360",
      },
      bo_access: {
        label: "5 Accès back office supplémentaires\xA0:",
        price: "50€\xA0HT/Accès/mois",
      },
    },
    services: {
      title: "Services Kazaplan\xA0:",
      build: {
        label:
          "Modélisation d’une maison (hors aménagement mobilier et jardin) par l’équipe Kazaplan selon un plan détaillé\xA0:",
        price: "350€\xA0HT",
      },
      formation: {
        label: "Formation visio par session de 2H\xA0:",
        price: "250€\xA0HT",
      },
      help: {
        label: "Accompagnement à l’intégration\xA0:",
        price: "125€\xA0HT/Heure",
      },
    },
  },
  about: {
    title:
      "LE KAZAPLAN EST LE FRUIT DE LA CRÉATION DE 30 PASSIONNÉS D'IT & DE MAISON REGROUPÉS AU SEIN DE LA SOCIÉTÉ KOZIKAZA",
    desc_1:
      "Kozikaza est une start up Française aux ambitions internationales.",
    desc_2:
      "Plus d'un million sont faits par des particuliers chaque année sur notre outil de plan 3D et notre objectif est de multiplier ce chiffre par 10 en 5 ans\xA0!",
    job_contact: "nous contacter pour un job",
    meetup_contact: "nous contacter pour un rdv",
    press_release: "LA PRESSE PARLE DE NOUS\xA0:",
  },
  contact: {
    home_builder: "Constructeur de MI",
    furniture_distributor: "Distributeur de mobilier",
    material_distributor: "Distributeur de matériaux, GSB",
    other: "Autre",
    title:
      "VOTRE BUSINESS N'ATTEND PAS, NOUS VOUS RECONTACTONS AU PLUS VITE\xA0!",
    company: "société",
    country: "pays",
    first_name: "prénom",
    last_name: "nom",
    email: "email",
    phone: "numéro de téléphone",
    business: "typologie de business",
    message: "message",
    contact_button: "contactez-nous pour une demo",
    confirmation: "Votre message a bien été envoyé",
    error: "Une erreur est survenue, merci de réessayer plus tard",
    contact_mentions:
      "En cliquant sur \"Contactez-nous\" vous acceptez nos conditions générales d'utilisation. Les informations recueillies à partir de ce formulaire font l’objet d’un traitement automatisé dont le responsable est IDILINK. Pour en savoir plus, reportez-vous à nos conditions générales d'utilisation (https://kazaplan.com/cgu>). Conformément à la loi «\xA0informatique et libertés\xA0» du 6 janvier 1978 modifiée, vous disposez d’un droit d’accès, de rectification et de suppression des données à caractère personnel qui vous concernent. Pour des motifs légitimes, vous pouvez également vous opposer au traitement des données vous concernant. Vous pouvez exercer vos droits en vous adressant à\xA0: contact@kazaplan.com",
  },
  trust: {
    title: "Ils nous font confiance",
  },
  furniture: {
    title: "Leurs mobiliers sont déjà sur Kozikaza.com",
  },
  contact_us: "Contactez-nous",
  tou: {
    last_change: "Date de dernière mise à jour\xA0: ",
  },
  privacy: {
    last_change: "Date de dernière mise à jour\xA0: ",
  },
  backup: {
    title: "Vous cherchez vos plans sur le site ?",
    desc_1: "Vous pourrez dorénavant les retrouver sur Kozikaza.com",
    desc_2: `Pour cela, inscrivez l'adresse mail de votre compte Kazaplan ici :`,
    desc_3: `Vous allez recevoir un e-mail d'activation de votre compte Kozikaza`,
    msg_error_1: `Aucun compte n'est associé avec cette adresse e-mail. Un problème ? Contactez le support contact@kozikaza.com`,
    msg_error_2:
      "Une erreur est survenue, merci de contacter le support contact@kozikaza.com",
    send: "Envoyer",
    msg_success: "E-mail envoyé",
  },
  examples: {
    home: "Quelques modèles de visuels réalistes tirés de plans 3D faits avec le logiciel maison 3D Kazaplan",
    constructor:
      "Découvrez des exemples de visuels 3D réalistes obtenus via le logiciel de plan de maison 3D Kazaplan",
    material:
      "Retrouvez des exemples de visuels 3D réalistes réalisés avec l’outil 3D Kazaplan",
    furniture:
      "Découvrez quelques exemples de visuels HD et 360° de plans réalisés avec le logiciel de plan 3D Kazaplan",
  },
  kozikaza: {
    link: "https://www.kozikaza.com/",
  },
};

export default translations;
