import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";

import Icon from "../../components/Icon";
import Trust from "../../components/Trust";
import Prices from "../../components/Prices";
import Discover from "../../components/Discover";
import CustomSlider from "../../components/Slider";
import Examples from "../../components/Examples";

import { Layout, HelmetCanonical } from "../../components";

import "./Home.scss";

const Home = ({
  match: {
    routeKeys,
    params: { locale },
  },
}) => {
  const [dismissBackup, setDismissBackup] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const prefix = locale ? `/${locale}` : "";
  const slides = [
    {
      defaultSrc: "/homepage/carousel-01.png",
      mobileSrc: "/homepage/carousel-01-mobile.png",
      alt: t("images.alt.hp.carousel_01"),
    },
    {
      defaultSrc: "/homepage/carousel-02.png",
      mobileSrc: "/homepage/carousel-02-mobile.png",
      alt: t("images.alt.hp.carousel_02"),
    },
    {
      defaultSrc: "/homepage/carousel-03.png",
      mobileSrc: "/homepage/carousel-03-mobile.png",
      alt: t("images.alt.hp.carousel_03"),
    },
  ];

  const imagesExamples = [
    {
      url: "/HP/1_SALON.jpg",
      translationKey: "hp.salon_1",
      alt: t("images.alt.hp.example_01"),
    },
    {
      url: "/HP/2_MAISON.jpg",
      translationKey: "hp.maison_2",
      alt: t("images.alt.hp.example_02"),
    },
    {
      url: "/HP/3_SDB.jpg",
      translationKey: "hp.sdb_3",
      alt: t("images.alt.hp.example_03"),
    },
    {
      url: "/HP/4_SAM.jpg",
      translationKey: "hp.sam_4",
      alt: t("images.alt.hp.example_04"),
    },
  ];

  return (
    <Layout locale={language}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("seo.home.title")}</title>
        <meta name="description" content={t("seo.home.description")}></meta>
        <meta name="robots" content="index,follow"></meta>
        <html lang={language} />
      </Helmet>

      <HelmetCanonical routes={routeKeys} />

      <main className="kz-home">
        {!dismissBackup && !localStorage.getItem("dismissBackup") && false ? (
          <div className="kz-home--backup">
            <b>{t("home.backup.title")}</b>
            <Link to={`${prefix}/${t("route.backup")}`}>
              {t("home.backup.click")}
            </Link>
            <div
              className="kz-home--backup--dismiss"
              onClick={() => {
                // use state to delete current element
                setDismissBackup(true);
                // set to the local storage for next time
                localStorage.setItem("dismissBackup", true);
              }}
            >
              {t("home.backup.askme")}
            </div>
          </div>
        ) : null}
        <section className="kz-home--intro">
          <h1>
            <strong style={{ marginBottom: "1rem" }}>{t("home.kzp")}</strong>
            {t("home.title")} {t("home.title_2")}
          </h1>

          <Link
            to={`${prefix}/${t("route.demo")}`}
            className="kz-button kz-button--tools kz-button--flat"
          >
            <span>{t("button.ask_demo")}</span>
          </Link>

          <div className="kz-home-slider">
            <CustomSlider slides={slides} />
          </div>

          <div className="kz-home--intro-features">
            <p>{t("home.kzpintro")}</p>
            <ul>
              <li>
                <h3>
                  <Trans i18nKey="home.feature_1_head" />
                </h3>
                <Trans i18nKey="home.feature_1" />
              </li>
              <li>
                <h3>
                  <Trans i18nKey="home.feature_2_head" />
                </h3>
                <Trans i18nKey="home.feature_2" />
              </li>
              <li>
                <h3>
                  <Trans i18nKey="home.feature_3_head" />
                </h3>
                <Trans i18nKey="home.feature_3" />
              </li>
            </ul>
          </div>

          <Link
            to={`${prefix}/${t("route.demo")}`}
            className="kz-button kz-button--tools kz-button--flat"
          >
            <span>{t("button.ask_demo_ext")}</span>
          </Link>
        </section>

        <section className="kz-home--solutions">
          <div className="kz-home--solution">
            <Icon value="house-black" />
            <h2>{t("home.constructor.title")}</h2>
            <p>
              <Trans i18nKey="home.constructor.description" />
            </p>
            <Link
              to={`${prefix}/${t("route.individual-home-builders")}`}
              className="kz-button kz-button--tools kz-button--flat"
            >
              <span>{t("button.explore_solution")}</span>
            </Link>
          </div>

          <div className="kz-home--solution">
            <Icon value="materials-black" />
            <h2>{t("home.decoration_distributor.title")}</h2>
            <p>
              <Trans i18nKey="home.decoration_distributor.description" />
            </p>
            <Link
              to={`${prefix}/${t("route.furniture-distributor")}`}
              className="kz-button kz-button--tools kz-button--flat"
            >
              <span>{t("button.explore_solution")}</span>
            </Link>
          </div>

          <div className="kz-home--solution">
            <Icon value="furniture-black" />
            <h2>{t("home.material_distributor.title")}</h2>
            <p>
              <Trans i18nKey="home.material_distributor.description" />
            </p>
            <Link
              to={`${prefix}/${t("route.building-material-distributor")}`}
              className="kz-button kz-button--tools kz-button--flat"
            >
              <span>{t("button.explore_solution")}</span>
            </Link>
          </div>
        </section>

        <section className="kz-home--features kz-carousel">
          <div className="kz-home--features-container kz-carousel--container">
            <div className="kz-home--feature kz-home--feature-plan2D">
              <div className="kz-home--content">
                <h3>{t("home.plan2d.title")}</h3>
                <p>
                  <Trans i18nKey="home.plan2d.description" />
                </p>
                <strong>
                  <Trans i18nKey="home.plan2d.want_it" />
                </strong>
                <Link
                  to={`${prefix}/${t("route.demo")}`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("button.ask_demo")}</span>
                </Link>
              </div>
            </div>

            <div className="kz-home--feature kz-home--feature-plan3D">
              <div className="kz-home--content">
                <h3>{t("home.plan3d.title")}</h3>
                <p>
                  <Trans i18nKey="home.plan3d.description" />
                </p>
                <strong>
                  <Trans i18nKey="home.plan3d.want_it" />
                </strong>
                <Link
                  to={`${prefix}/${t("route.demo")}`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("button.ask_demo")}</span>
                </Link>
              </div>

              <div className="kz-home--video-container">
                <div className="kz-home--video">
                  <Icon value="play" />
                  <img
                    src={require("../../assets/img/homepage/video.png").default}
                    loading="lazy"
                    alt={t("images.alt.hp.video")}
                  />
                </div>
              </div>
            </div>

            <div className="kz-home--feature kz-home--feature-planHD">
              <div className="kz-home--content">
                <h3>{t("home.planHD.title")}</h3>
                <p>{t("home.planHD.desc_1")}</p>
                <ul>
                  <li>
                    <img
                      src={require("../../assets/img/homepage/360.png").default}
                      loading="lazy"
                      alt={t("images.alt.hp.h_360d")}
                    />
                    <strong>{t("labels.360_views")}</strong>
                  </li>
                  <li>
                    <img
                      src={
                        require("../../assets/img/homepage/3dhd.png").default
                      }
                      loading="lazy"
                      alt={t("images.alt.hp.h_3dhd")}
                    />
                    <strong>{t("labels.hd_image")}</strong>
                  </li>
                </ul>
                <p>{t("home.planHD.desc_2")}</p>
                <strong>
                  <Trans i18nKey="home.planHD.want_it" />
                </strong>
                <Link
                  to={`${prefix}/${t("route.demo")}`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("button.ask_demo")}</span>
                </Link>
              </div>
            </div>
          </div>

          <ul className="kz-carousel--pagin">
            <li className="kz-carousel--pagin-item current"></li>
            <li className="kz-carousel--pagin-item"></li>
            <li className="kz-carousel--pagin-item"></li>
          </ul>
        </section>

        <Examples title={"examples.home"} imagesExamples={imagesExamples} />

        <Prices
          title={t("home.prices.title")}
          description={t("home.prices.description")}
        />

        <Trust />

        <Discover description="true" />
      </main>
    </Layout>
  );
};

export default Home;
