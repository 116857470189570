import React, { Component } from "react";
import Icon from "../Icon";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import "./Footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.scrollToTop = this.scrollToTop.bind(this);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    const {
      t,
      match: {
        params: { locale },
      },
    } = this.props;
    const prefix = locale ? `/${locale}` : "";
    return (
      <footer className="kz-footer">
        <div className="kz-footer-scrollToTop">
          <span className="kz-scrollToTop" onClick={this.scrollToTop}>
            <Icon value="arrow-up" />
          </span>
        </div>
        <div className="kz-footer-container">
          <div className="kz-footer--links">
            <div>
              <NavLink to={`${prefix}/${t("route.about")}`}>
                {t("footer.about")}
              </NavLink>
              <NavLink to={`${prefix}/${t("route.legal-notice")}`}>
                {t("footer.legal")}
              </NavLink>
            </div>
            <div>
              <span>{t("footer.kazaplan_solutions")}</span>
              <NavLink to={`${prefix}/${t("route.tool.privacy-policy")}`}>
                {t("footer.privacy")}
              </NavLink>
              <NavLink to={`${prefix}/${t("route.tool.terms-of-use")}`}>
                {t("footer.tou")}
              </NavLink>
              <NavLink to={`${prefix}/${t("route.tool.terms-of-sales")}`}>
                {t("footer.tos")}
              </NavLink>
            </div>
            <div>
              <span>{t("footer.kazaplan_website")}</span>
              <NavLink to={`${prefix}/${t("route.website.privacy-policy")}`}>
                {t("footer.privacy")}
              </NavLink>
              <NavLink to={`${prefix}/${t("route.website.terms-of-use")}`}>
                {t("footer.tou")}
              </NavLink>
            </div>
            {/* <div>
              <span>{t('footer.follow')}</span>
              <a className="kz-social" href="https://fr.linkedin.com/company/atelier-kazaplan" rel="noopener noreferrer" target="_blank"><Icon value="social-linkedin" /></a>
              <a className="kz-social" href="https://www.facebook.com/kazaplan/" rel="noopener noreferrer" target="_blank"><Icon value="social-fb" /></a>
            </div> */}
          </div>
          <div className="kz-footer--infos">
            <span>135 Rue Sadi Carnot, 59790 Ronchin</span>
            <span>2019 &copy; Kazaplan &amp; Kozikaza</span>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(withTranslation()(Footer));
