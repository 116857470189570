import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import Icon from '../../components/Icon'

import './PricesEarnings.scss'

class PricesEarnings extends Component {
  render() {
    const { t } = this.props

    return (
      <div className="kz-pricing--earnings">
        <p>{t('pricing.pricing_earnings.title')}</p>

        <div className="kz-carousel">
          <ul className="kz-carousel--container">
            <li className="current">
              <Icon value="check-green" />
              <p>{t('pricing.pricing_earnings.check_1')}</p>
            </li>
            <li>
              <Icon value="check-green" />
              <p>{t('pricing.pricing_earnings.check_2')}</p>
            </li>
            <li>
              <Icon value="check-green" />
              <p>{t('pricing.pricing_earnings.check_3')}</p>
            </li>
          </ul>
          <ul className="kz-carousel--pagin">
            <li className="kz-carousel--pagin-item current"></li>
            <li className="kz-carousel--pagin-item"></li>
            <li className="kz-carousel--pagin-item"></li>
          </ul>
        </div>
      </div>
    )
  }
}

export default withTranslation()(PricesEarnings)
