import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { PropagateLoader } from "react-spinners";
import { Layout } from "../../components";

import "./Backup.scss";

export default function Backup() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(undefined);
  const [success, showSuccess] = useState(false);
  const [fetching, isFetching] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    if (email.length > 0) {
      isFetching(true);

      fetch(
        `${process.env.REACT_APP_BACKUP_EMAIL}?email=${email}&language=${language}`
      ).then((res) => {
        if (res.status === 200) {
          isFetching(false);
          setEmail("");
          showSuccess(true);
        }
        if (res.status === 400 || res.status === 404) {
          isFetching(false);
          setError(t("backup.msg_error_1"));
        }
        if (res.status === 500) {
          isFetching(false);
          setError(t("backup.msg_error_2"));
        }
      });
    }
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backup plans</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <div className="kz-backup">
        <img
          src={require("../../assets/img/backup.png").default}
          loading="lazy"
          alt="backup plans"
        ></img>
        <h1>{t("backup.title")}</h1>

        <div className="kz-backup--details">
          <p>{t("backup.desc_1")}</p>
          <p>{t("backup.desc_2")}</p>
          <div>
            <form onSubmit={submit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={fetching}
              />
              <br />
              {fetching ? (
                <PropagateLoader
                  css={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "30px",
                  }}
                  color={"#3B8F7B"}
                />
              ) : (
                <input
                  type="submit"
                  value={t("backup.send")}
                  className="kz-button kz-button--tools kz-button--flat"
                />
              )}
            </form>
          </div>
          {error && !success && !fetching ? (
            <p className="kz-backup--error">{error}</p>
          ) : null}
          {success ? (
            <p className="kz-backup--success">{t("backup.msg_success")}</p>
          ) : null}
          {!success ? <p>{t("backup.desc_3")}</p> : null}
        </div>
      </div>
    </Layout>
  );
}
