import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import "./NotFound.scss";

const NotFound = () => {
  const {
    i18n: { language: locale },
  } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Not Found</title>
        <meta name="prerender-status-code" content="404" />
        <meta name="robots" content="index,follow"></meta>
        <html lang={locale} />
      </Helmet>
      <h1>404 Not Found ({locale})</h1>
    </>
  );
};

export default NotFound;
