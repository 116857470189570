import React from "react";
import { useTranslation } from "react-i18next";
import ImageWithOverlay from "../ImageWithOverlay";
import "./Examples.scss";

const Examples = (props) => {
  const { t } = useTranslation();
  return (
    <div className="kz-examples">
      <h2>{t(props.title)}</h2>
      <div className="kz-examples--images">
        {props.imagesExamples.map((image, index) => (
          <ImageWithOverlay
            key={index}
            url={image.url}
            translationKey={image.translationKey}
            alt={image.alt}
          />
        ))}
      </div>
    </div>
  );
};

export default Examples;
