const translations = {
  images: {
    alt: {
      hp: {
        h_360d: "Funkcja zrzutu 360° H",
        h_3dhd: "Funkcja zrzutu HD",
        carousel_01: "Zrzut HD domu z zewnątrz",
        carousel_02: "Umieszczenie fotela w projekcie 3D",
        carousel_03: "Projektowanie terenu 3D w narzędziu",
        example_01: "Rendering HD czarnej nowoczesnej łazienki w drewnie",
        example_02: "Rendering HD domu z garażem i ogrodem",
        example_03: "Rendering HD biało-czarnej kuchni",
        example_04: "Zrzut HD nowoczesnej jadalni",
        features_01:
          "Zdjęcie użytkownika tworzącego swój projekt 2D na Kazaplan",
        features_02: "Lista mebli i przedmiotów dostępnych w narzędziu",
        features_03: "Zrzut HD nowoczesnego salonu",
        video: "Samouczek wideo dotyczący korzystania z projektu 3D",
      },
      furniture: {
        feature_01: "Widok logo marki partnerskiej na kazaplan",
        feature_02: "Prezentacja funkcji „dodaj do koszyka” Kazaliste",
        feature_03: "Schemat akwizycji danych wprowadzanych przez użytkowników",
        feature_04:
          "Przykłady wizualizacji HD wykonanych na narzędziu projektu 3D",
        ecommerce:
          "Wyświetlanie marki znaku partnera na prezentowanym obiekcie",
        carousel_01: "Zrzut 3D HD nowoczesnego salonu",
        carousel_02: "Wybór wyposażenia dostępnego w katalogu Kazaplan",
        carousel_03: "Układ projektu 3D w narzędziu kazaplan",
        example_01:
          "Projekt 3D parterowego domu o architekturze prowansalskiej",
        example_02: "Projekt 2D domu jednorodzinnego o powierzchni 100 m²",
        example_03: "Wersja 3D domu jednorodzinnego o powierzchni",
        example_04: "Wizualizacja 3D domu 100 m² z garażem i dużym ogrodem",
      },
      home_builder: {
        feature_01: "Zrzuty HD wykonane w narzędziu Kazaplan",
        feature_02: "Materiały i elementy budowlane dostępne na Kazaplan",
        feature_03: "Schemat kwalifikacji danych wprowadzonych na Kazaplan",
        carousel_01: "Rendering HD pojedynczego domu",
        carousel_02: "Modelowanie 3D domu na Kazaplan",
        carousel_03: "Galeria Projektów modeli 3D",
        example_01: "Dom 3D z dużym nowoczesnym salonem",
        example_02: "Nowoczesny czarno-biały projekt łazienki",
        example_03: "Wizualizacja 3D dużej białej kuchni w drewnie",
        example_04: "Salon nowoczesny i jasne drewno biało-szare",
      },
      material_distributor: {
        feature_01: "Tworzenie projektu instalacji elektrycznej Kazaplan",
        feature_02: "Przykłady zrzutów HD wykonanych na Kazaplan",
        feature_03: "Tabela podglądu powierzchni w narzędziu 3D",
        carousel_01: "Widok wyboru cegły i kamienia dostępnych w narzędziu",
        carousel_02:
          "Krzesła ze znakiem partnera dostępne w narzędziu do projektowania 3D",
        carousel_03: "Zrzut HD projektu domu w 3D",
        example_01: "Wizualizacja 3D domu architekta z dużym ogrodem",
        example_02:
          "Schemat elektryczny 2D domu wykonany za pomocą naszego konfiguratora projektów instalacji elektrycznych",
        example_03: "Renderowanie 3D łazienki z płytkami cementowymi",
        example_04: "Projekt 2D ze szczegółami powierzchni",
      },
      about: {
        frenchtech: "Logo French Tech",
        press_adn: "Artykuł prasowy o Kozikaza z magazynu ADN",
        press_bfm: "Promocja Kozikaza na BFM Business Media",
      },
      trust: {
        autourdebb: "Logo ADBB",
        home_builder: "Logo Constructeurdemaison.net",
        lecubefrancais: "Logo Cube Français",
        leroymerlin: "Logo Leroy Merlin",
        quotatis: "Logo Quotatis",
      },
    },
    examples: {
      hp: {
        salon_1:
          "Unikalny współczesny projekt domu z garażem i dużym zadaszonym drewnianym tarasem. Czerwona cegła elewacji i dach z dachówką z uwydatnionym realizmem dzięki wizualizacji 3D.",
        maison_2:
          "Dom zaprojektowany przez architekta, aby jak najlepiej wykorzystać zielone otoczenie. Parkiet i drewniane belki na suficie nadają przytulną atmosferę czarno-białemu wnętrzu tego pięknego, nowoczesnego domu z wysokim sufitem.",
        sdb_3:
          "Materiały i schludny wystrój wnętrz podkreślają tę piękną designerską łazienkę o powierzchni 5 m². Duża wanna narożna, podwójna umywalka i dużo miejsca do przechowywania sprawiają, że jest to pomieszczenie funkcjonalne i rodzinne.",
        sam_4:
          "Kuchnia 3D o opływowym i nowoczesnym designie, z białymi meblami, czarnymi blatami i dużą strefą jadalni. Pokój jest zalany światłem wpadającym przez duże okno wykuszowe z widokiem na ogród.",
      },
      furniture: {
        sam_1:
          "Projekt salonu: Rendering 3D pięknego salonu z katedralnym sufitem, który swoim współczesnymi meblami i wystrojem podkreśla skandynawski wystrój wnętrz.",
        sdb_2:
          "Projekt łazienki: Projekt łazienki z motywem czerni i drewna. Nad podwójną szafką podumywalkową, obok szklanej ścianki wanny, wisi duże drewniane lustro.",
        cuisine_3:
          "Projekt kuchni: Wizualizacja 3D dużej w pełni wyposażonej kuchni utrzymanej w jasnej kolorystyce, z białymi meblami i drewnianymi blatami. Włączenie sprzętów kuchennych i dekoracji sprawia, że scena jest bardziej realistyczna.",
        salon_4:
          "Współczesny salon i jadalnia z dominującym motywem drewna, bieli i szarości. Jest udekorowana i umeblowana w najdrobniejszych szczegółach i jest tak realistyczna, że wyrenderowany obraz 3D może uchodzić za prawdziwą fotografię.",
      },
      constructor: {
        maison_1:
          "Projekt 3D parterowego domu o powierzchni 90 m² wybudowanego w tradycyjnym francuskim stylu prowansalskim, wraz z garażem. Porysowana, tynkowa elewacja budynku oraz dach pokryty romańską dachówką, doskonale uzupełniają drewnianą pergolę zadaszonego tarasu",
        plan_2:
          "Rzut 2D wyjątkowego domu jednorodzinnego o powierzchni 100 m². Dwie łazienki i otwarta kuchnia, która prowadzi do jasnego i przestronnego salonu sprawiają, że jest to idealny dom dla rodziny z jednym lub dwójką dzieci.",
        vueciel_3:
          "Wersja 3D domu jednorodzinnego o powierzchni 100 m². Aranżacja wnętrza, która obejmuje współczesne meble i modny wystrój, wzbogaca dom i ułatwia jego zaprojektowanie.",
        maison_4:
          "Dom 100 m² z zaadaptowanym poddaszem, garażem i dużym ogrodem. Elewacja jest renderowana w widoku 3D z pięknym drewnianym tarasem, domkiem ogrodowym i placem zabaw dla dzieci. Już czuję się jak w domu…",
      },
      material: {
        ext_1:
          "Piękny dom zaprojektowany przez architekta, aby jak najlepiej wykorzystać zielone otoczenie. Kompozytowa okładzina zewnętrzna i markiza zakrywająca zewnętrzny taras sprawiają, że jest to wyjątkowa nieruchomość.",
        elec_2:
          "Projekt instalacji elektrycznej domu w 2D stworzony za pomocą naszego konfiguratora instalacji. Uwzględniono wszystkie podzespoły elektryczne: gniazda, włączniki, punkty świetlne i tak dalej. Tutaj pokazano również połączenia kabli elektrycznych.",
        sdb_3:
          "Rendering 3D łazienki z parkietem w stylu pokładu łodzi. Na podeście wanny kafelki i ceramika zostały obrobione w eleganckim stylu retro",
        plan_4:
          "Wszystkie projekty 2D zawierają szczegółowe wymiary każdego pomieszczenia, co ułatwia uzyskanie powierzchni i dokładnych pomiarów całego domu i jego elewacji.",
      },
    },
  },
  seo: {
    home: {
      title:
        "Profesjonalne oprogramowanie do projektowania domów 3D online - Kazaplan.com",
      description:
        "Kazaplan to profesjonalne oprogramowanie do projektowania 2D 3D, które umożliwia modelowanie domów i mieszkań za pomocą zaledwie kilku kliknięć. Kazaplan wspiera działalność użytkowników w obszarze budowy domów, dystrybucji mebli i materiałów.",
    },
    business: {
      furniture_distributor: {
        title:
          "3D i Wirtualna Rzeczywistość (VR) do sprzedaży mebli i dekoracji - Kazaplan.com",
        description:
          "Zwiększ sprzedaż online dzięki 3D i wirtualnej rzeczywistości oprogramowania Kazaplan. Pomóż swoim klientom wyobrazić sobie ich domy za pomocą modeli 3D produktów",
      },
      material_distributor: {
        title:
          "Konfigurator 3D sprzedaży materiałów budowlanych - Kazaplan.com",
        description:
          "Sprzedawaj swoje panele ścienne i podłogowe, materiały budowlane, wyposażenie elektryczne za pomocą naszego internetowego konfiguratora 2D/3D. Klienci mogą stworzyć rzut piętra lub zaprojektować dom i przekazać Ci wszystkie istotne dane.",
      },
      home_builder: {
        title:
          "Oprogramowanie do projektowania 3D dla budowniczych domów jednorodzinnych – Kazaplan.com",
        description:
          "Oprogramowanie do projektowania Kazaplan 3D pomoże Ci w sprzedaży Twoich domów na każdym etapie procesu dzięki kompletnemu rozwiązaniu projektowemu 2D i 3D",
      },
    },
    pricing: {
      title: "Cennik – Kazaplan.com",
    },
    about: {
      title: "O nas – Kazaplan.com",
    },
    contact: {
      title: "Zamów demo – Kazaplan.com",
    },
  },
  route: {
    "individual-home-builders": "budowniczy-domow-jednorodzinnych",
    "furniture-distributor": "dystrybutor-mebli",
    "building-material-distributor": "dystrybutor-materialow-budowlanych",
    tool: {
      "privacy-policy": "narzedzie/polityka-prywatnosci",
      "terms-of-use": "narzedzie/warunki-uzytkowania",
      "terms-of-sales": "narzedzie/warunki-sprzedazy",
    },
    website: {
      "privacy-policy": "stronie-internetowej/polityka-prywatnosci",
      "terms-of-use": "stronie-internetowej/warunki-uzytkowania",
      "terms-of-sales": "stronie-internetowej/warunki-sprzedazy",
    },
    demo: "demo",
    pricing: "cennik",
    api: "dokumentacja-api",
    about: "o-nas",
    "legal-notice": "nota-prawna",
    backup: "kopie-zapasowe-projektow",
  },
  button: {
    ask_demo: "Zamów demo",
    ask_demo_ext: "Zamów demo Kazaplan",
    personnal_user: "Jestem użytkownikiem indywidualnym",
    explore_solution: "Poznaj rozwiązanie",
    pricing: "Cennik",
    discover: "Dowiedz się więcej o Kozikaza",
    try_3D: "Chcesz wypróbować oprogramowanie do projektowania 3D\xA0?",
  },
  labels: {
    "360_views": "Widoki 360",
    hd_image: "Obraz HD",
  },
  navbar: {
    menu: {
      home: "Strona główna",
      business: {
        home: "Dla Twojej działalności",
        home_builder: "Budowniczy",
        furniture_distributor: "Dystrybutor mebli",
        material_distributor: "Dystrybutor materiałów",
      },
      pricing: "Cennik",
    },
  },
  footer: {
    api: "Dokumentacja API",
    about: "o-nas",
    tou: "Warunki użytkowania",
    tos: "Warunki sprzedaży",
    privacy: "Polityka prywatności",
    legal: "Nota prawna",
    follow: "Obserwuj nas",
    kazaplan_solutions: "Rozwiązania Kazaplan",
    kazaplan_website: "Strona internetowa kazaplan.com",
  },
  home: {
    kzp: "Kazaplan",
    title: "profesjonalne oprogramowanie do projektowania domu w 3D",
    title_2: "które rozkręci działalność bezpośrednio na Twojej stronie",
    kzpintro:
      "Kazaplan to profesjonalne oprogramowanie do modelowania 3D, które umożliwia projektowanie domów i mieszkań za pomocą zaledwie kilku kliknięć.",
    feature_1_head: "Wysoka wydajność",
    feature_2_head: "Intuicyjna obsługa",
    feature_3_head: "100% online",
    feature_1:
      "Szybkie oprogramowanie z szeroką gamą funkcji pozwala błyskawicznie tworzyć projekty domów 3D",
    feature_2:
      "Oprogramowanie nie tylko dla architektów zaprojektowano z myślą o wszystkich typach użytkowników.",
    feature_3:
      "Nie ma potrzeby pobierania oprogramowania, projekty są dostępne w przeglądarce.",
    constructor: {
      title: "Budowniczy domów jednorodzinnych",
      description: `<strong>Promuj swoją ofertę, przyciągaj klientów i pomóż swoim zespołom sprzedaży sprzedawać swoje nieruchomości dzięki 3D!</strong>
          Nowy dom to obietnica nowego życia dla nabywców domu. Postaw wszystkie szanse po swojej stronie dzięki temu starannie opracowanemu narzędziu sprzedaży.
          Niezależnie od tego, czy dostarczasz indywidualnie zaprojektowane domy, czy masz katalog istniejących nieruchomości, projektowanie i realizacja projektów z Kazaplan będzie łatwiejsze. Idealny kreator projektów dla Twoich klientów, Kazaplan pozwala zaprojektować każdy model domu w 3D\xA0: działki, projekty współczesne lub tradycyjne, domy z płaskim dachem, domy parterowe lub domy drewniane!`,
    },
    decoration_distributor: {
      title: "Dystrybutorzy mebli i dekoracji wnętrz",
      description: `<strong>Sprzedawaj meble bezpośrednio na swojej stronie dzięki wirtualnej rzeczywistości.</strong>
          Twoi klienci, biorąc udział w projekcie aranżacji wnętrz, muszą wyobrazić sobie siebie w swoich przyszłych domach i wyobrazić sobie wnętrze umeblowane Twoimi produktami.
          Dzięki Kazaplan będą mogli zaprojektować własny wirtualny dom i wybrać rzut piętra i układ każdego pomieszczenia w wybranym przez siebie czasie. Narzędzie do projektowania 3D pozwoli im poznać proporcje i faktury, a także kolory każdego mebla, oprawy oświetleniowej i dekoracji. Jakby tam!`,
    },
    material_distributor: {
      title: "Dystrybutorzy materiałów i sklepy z artykułami budowlanymi",
      description: `<strong>Wzbogać swoją ofertę i sprzedawaj materiały dzięki dostosowanym konfiguratorom zintegrowanym z Twoją stroną internetową.</strong>
          Biorąc udział w projekcie budowlanym lub remontowym, Twoi klienci muszą być w stanie wyobrazić sobie wynik i oszacować związane z tym koszty, aby mogli wybrać swoje materiały. Atrakcyjne wizualizacje podkreślą Twoje produkty.
          Konfigurator 2D lub 3D, który uwzględnia Twoje zasady prowadzenia działalności, pozwoli Twojemu klientowi na niezależne projektowanie. Z Kazaplan wszystko jest możliwe: stwórz kuchnię 3D lub łazienkę 3D, zobacz schemat instalacji elektrycznej, a nawet wypróbuj różne wykładziny podłogowe i ścienne.`,
    },
    plan2d: {
      title: "Narysuj projekt 2D",
      description: `Dzięki naszemu szybkiemu i łatwemu w obsłudze oprogramowaniu 3D wystarczy kilka minut, aby stworzyć projekt 2D pomieszczenia lub całego domu.
          Zacznij od narysowania ścian i ścianek działowych za pomocą przyjaznego dla użytkownika programu do projektowania pomieszczeń, dodaj więcej poziomów, a następnie za pomocą kilku kliknięć możesz umieścić drzwi, okna i schody, a nawet narysować poddasze i przestrzeń pod okapami.<br /><br />
          Pożegnaj się ze skomplikowanym oprogramowaniem do projektowania 3D zarezerwowanym dla pracowni architektonicznych i wybierz oprogramowanie do projektowania domów Kazaplan: narzędzie do projektowania domów 3D zaprojektowane z myślą o.`,
      want_it: "Chcesz wypróbować oprogramowanie<br />do projektowania?",
    },
    plan3d: {
      title: "Umebluj i udekoruj swój projekt 3D",
      description: `Wymyśl projekt wnętrza swojego domu, wstawiając w nim własne meble i dekoracje renderowane w 3D.<br />
          Przejrzyj nasz stale rosnący katalog i wybieraj spośród ponad 2500 obiektów 3D, aby wirtualnie umeblować i ozdobić wnętrze i elewacje swojego domu za pomocą nowoczesnych mebli, najnowszego wyposażenia wnętrz, urządzeń elektrycznych i AGD.`,
      want_it:
        "Chcesz wypróbować oprogramowanie<br/> do projektowania wnętrz 3D?",
    },
    planHD: {
      title:
        "Od modelu 3D do realistycznego renderowania w wysokiej rozdzielczości",
      desc_1:
        "W ciągu kilku minut klient lub współpracownik otrzyma w swojej skrzynce odbiorczej rendering 3D HD lub widok 360° pomieszczenia",
      desc_2: `Gdy model 3D jest gotowy, kilka kliknięć wygeneruje obraz 3D tak realistyczny, że będzie wyglądać jak fotografia. A następnie? Możesz rozpocząć wirtualną wycieczkę po domu i wizualizować go tak, jakbyś naprawdę tam był. Nie ma lepszego sposobu`,
      want_it: "Chcesz wypróbować oprogramowanie<br/> do modelowania 3D",
    },
    examples: {
      title:
        "Niektóre realistyczne wizualizacje zaczerpnięte z projektów 3D wykonanych za pomocą oprogramowania do projektowania domu Kazaplan 3D",
      more_information: "Więcej informacji",
    },
    prices: {
      title:
        "Niezależnie od tego, czy Twoja działalność obejmuje budowanie domów jednorodzinnych, sprzedaż mebli czy dystrybucję materiałów budowlanych, nasze oprogramowanie do modelowania 3D pomoże Ci i doskonale wpasuje się w Twoje profesjonalne narzędzia.",
      description:
        "Tworzenie projektów 2D i 3D, konfiguratory biznesowe, wirtualna wycieczka 3D i wizualizacje w wysokiej rozdzielczości wzbogacą Twoją ofertę produktową – wszystkie są potężnymi narzędziami, które wyróżnią Cię na tle konkurencji. Zacznij już dziś i zamów wersję demonstracyjną Kazaplan: jedno z najlepszych narzędzi do projektowania domów 3D, łatwe w użyciu i w pełni dostępne online. Zostało stworzony z myślą o wszystkich profesjonalistach zajmujących się budową domów i aranżacją wnętrz.",
    },
    discover: {
      title:
        "<strong>Jesteś użytkownikiem indywidualnym</strong> lub <strong>projektantem wnętrz</strong> \xA0?",
      description:
        "Odwiedź Kozikaza.com, gdzie znajdziesz bezpłatne oprogramowanie do projektowania domów Kozikaza 3D, a także społeczność indywidualnych użytkowników zaangażowanych w projekty budowy domów, renowacji i remontów. Na Kozikaza.com co roku tworzony się ponad milion projektów domów.",
    },
  },
  business: {
    home_builder: {
      title: "Kazaplan, oprogramowanie do projektowania 3D",
      title_2: "dla budowniczych domów jednorodzinnych",
      sell_more: "sprzedaj więcej domów\xA0!",
      desc_1: "Online - Łatwe w obsłudze - Bez zobowiązań",
      desc_2:
        "Oprogramowanie do projektowania Kazaplan 3D pomoże Ci w sprzedaży Twoich domów na każdym etapie procesu dzięki kompletnemu rozwiązaniu projektowemu 2D i 3D.",
      check_1: "Promuj swój katalog domów",
      check_2:
        "Zaoferuj swojemu zespołowi sprzedaży narzędzie wsparcia sprzedaży",
      check_3:
        "Przyciągnij więcej potencjalnych klientów, którzy mają projekt budowy domu",
      card_1_title:
        "PROMUJ SWOJE DOMY I ZWIĘKSZ WSKAŹNIK ZREALIZOWANEJ SPRZEDAŻY\xA0!",
      card_1_desc_1:
        "Przedstaw swoje wstępne projekty potencjalnym klientom za pomocą realistycznych obrazów 3D lub widoków 360°.",
      card_1_desc_2:
        "Prezentuj domy wyposażone we współczesne meble i modne wnętrza, a następnie zaproponuj wirtualny spacer po domu. Każdy pokój można zwiedzać tak, jakby się znajdowało wewnątrz niego. Wszystkie te rozwiązania dostarczane przez nasze oprogramowanie do projektowania domów pozwolą Ci na promocję i prezentację Twoich projektów. Obrazy 3D w wysokiej rozdzielczości wzbogacą Twoje domy, a wirtualna wycieczka 3D pozwoli potencjalnym nabywcom wyobrazić sobie siebie w swoim nowym domu.",
      card_2_title: "OSZCZĘDZAJ CZAS PODCZAS WSTĘPNEJ FAZY PROJEKTU",
      card_2_desc_1: "Czy masz katalog domów\xA0?",
      card_2_desc_2:
        "Twórz modele 2D i 3D swoich domów. Następnie pozwól swoim działom sprzedaży dostosować wnętrze i elewacje modeli do potrzeb potencjalnych klientów, korzystając z naszego katalogu obiektów 3D zawierających meble, dekoracje domu i elewacje. Narzędzie jest teraz oprogramowaniem do projektowania wnętrz.",
      card_2_desc_3: "Budujesz domy na zamówienie\xA0?",
      card_2_desc_4:
        "Zorganizuj swoje biuro projektowe, dostarczając zespołowi sprzedaży łatwe w obsłudze oprogramowanie",
      card_2_desc_5:
        "które pozwali im narysować projekt domu w 3D od początku do końca. Po ukończeniu projektu architektonicznego mogą umeblować i udekorować go w ciągu zaledwie kilku minut.",
      card_2_desc_6:
        "Dostosowany projekt 3D pozwala pokazać klientom różne opcje i szybko wybrać między dwoma rozwiązaniami.",
      card_2_desc_7:
        "Oprogramowanie do budowy domu Kazaplan 3D to idealne rozwiązanie, które pozwoli Ci osiągnąć cel, jakim jest umożliwienie Twoim klientom wyobrażenie sobie siebie w nowym wnętrzu dzięki renderowaniu 3D ich domu.",
      card_2_want_it: "Chcesz wypróbować oprogramowanie do budowy domu\xA0?",
      card_3_title: "ZBIERAJ DANE KONTAKTOWE ODWIEDZAJĄCYCH TWOJĄ STRONĘ",
      card_3_desc_1:
        "W przeciwieństwie do innych dostępnych do pobrania programów do projektowania, takich jak Cedreo lub Miao, Twoi klienci mogą swobodnie korzystać z naszego oprogramowania do budowania domów 3D, aby dopracowywać swoje projekty.",
      card_3_desc_2:
        "Pozwól odwiedzającym Twoją stronę na łatwe narysowanie projektu domu 3D lub dostosowanie projektów już dostępnych w katalogu, bezpośrednio na Twojej stronie, dzięki wielu zaawansowanym i konfigurowalnym funkcjom.",
      card_3_desc_3:
        "Dzięki oprogramowaniu do projektowania domów Kazaplan 3D potencjalni klienci mogą spersonalizować oferowane projekty domów: za pomocą zaledwie kilku kliknięć",
      card_3_desc_4:
        "interaktywne rzuty, ustawianie mebli i kompletne dekoracje wnętrz i elewacji domu. Oprogramowanie działa w tym przypadku jak narzędzie do projektowania wnętrz. Dział sprzedaży uzyska wszystkie informacje potrzebne do kontaktu z klientami i udzielania im pomocy, od wymaganego typu konstrukcji i liczby pomieszczeń, po ich układ i wyposażenie.",
      card_3_want_it:
        "Chcesz wypróbować oprogramowanie do projektowania budynków 3D?",
      business_examples:
        "Niektóre fotorealistyczne wizualizacje zaczerpnięte z projektów 3D wykonanych za pomocą oprogramowania do projektowania domu Kazaplan 3D",
      stand_out: {
        title:
          "Budowniczowie domów jednorodzinnych i firmy budowlane: skorzystaj z naszego oprogramowania do projektowania 3D, aby zwiększyć sprzedaż.",
        desc: "Modelowanie i wizualizacja projektów domów 2D i 3D, wirtualny spacer i fotorealistyczne obrazy w innowacyjny sposób zaprezentują Twoją ofertę. Wyróżnij się na tle konkurencji! Już dziś zamów wersję demonstracyjną Kazaplan: jednego z najbardziej zaawansowanych narzędzi do projektowania 3D, intuicyjne i bez konieczności pobierania. Zostało zaprojektowane z myślą o wszystkich profesjonalistach z branży budowlanej",
      },
      more_information: "Więcej informacji",
      read_more: "Więcej...",
    },
    furniture_distributor: {
      title: "KAZAPLAN\xA0: WYKORZYSTAJ WIRTUALNĄ RZECZYWISTOŚĆ W SPRZEDAŻY",
      title_2: "MEBLI I WYPOSAŻENIA WNĘTRZ ONLINE",
      title_3: "dla dostawców, sprzedawców internetowych i dystrybutorów",
      desc_1:
        "Oprogramowanie 3D dostępne na Twojej stronie – Agencja projektowania 3D do Twojej dyspozycji – Bez zobowiązań",
      card_1_title: "TWOJE MEBLE 3D WE WNĘTRZACH KLIENTÓW",
      card_1_desc_1:
        "W ciągu kilku minut odwiedzający Twoją stronę będą mogli umeblować swoje wnętrze 3D przedmiotami z Twojego katalogu mebli i dekoracji 3D. Dzięki narzędziu do doboru mebli, Kazaplan zapewnia pomoc w zakupach, która umożliwi odwiedzającym wyobrażenie sobie Twoich produktów w ich wnętrzach 3D.",
      card_1_want_it:
        "Chcesz wypróbować oprogramowanie do projektowania wnętrz 3D\xA0?",
      card_2_title: "ZWIĘKSZ ŚREDNIĄ KWOTĘ ZAMÓWIENIA DZIĘKI 3D",
      card_2_desc_1: "Zachęcaj do zakupów hurtowych lub spontanicznych!",
      card_2_desc_2:
        "Dzięki katalogowi mebli 3D klienci mogą mieszać i dopasowywać elementy z Twojej kolekcji, aby stworzyć niepowtarzalną atmosferę w swoim wnętrzu 3D. Podgląd 3D jest ważnym narzędziem sprzedaży przekrojowej. Skorzystaj z naszego narzędzia do aranżacji mebli, aby zwiększyć sprzedaż\xA0!",
      card_2_desc_3:
        "Niezależnie od tego, czy pracujesz z Magento, prestashop czy jakimkolwiek innym oprogramowaniem CMS, za pomocą jednego prostego kliknięcia nasze API pozwoli Twoim klientom umieścić listę artykułów Kazaplan w koszyku Twojego sklepu internetowego\xA0!",
      card_3_title: "ZBIERAJ DANE KONTAKTOWE ODWIEDZAJĄCYCH TWOJĄ STRONĘ",
      card_3_desc_1:
        "Dzięki Kazaplan Twoi klienci mogą zaprojektować swój salon, kuchnię, garderobę i sypialnię w 3D. To idealny moment, aby się z nimi skontaktować i porozmawiać o ich projekcie! Kazaplan można również podłączyć do Twojego narzędzia CRM dzięki naszemu API, co pozwala na rozwijanie lead nurturingu.",
      card_3_desc_2:
        "Klienci wyposażają swoje projekty w Twoje meble > zapisuj plany wraz z danymi kontaktowymi > lead nurturing",
      card_4_title:
        "ZANURZ SWOICH KLIENTÓW W WIRTUALNYCH SALONACH Z RENDEROWANIEM 3D I WIDOKAMI 360°",
      card_4_desc_1:
        "Renderingi 3D HD są tak realistyczne, że wyglądają jak prawdziwe fotografie. Widok 360° umożliwia oglądanie całego pokoju tak, jakby przebywało się w jego wnętrzu!",
      card_4_desc_2:
        "Twórz galerie fotorealistycznych renderingów i promuj swoje produkty. Twórz różne wystroje w 3D i oszczędzaj pieniądze, zmniejszając potrzebę posiadania studia fotograficznego! Nasze studio 3D w pełni zajmie się modelowaniem Twoich produktów w obiekty 3D, a Tobie pozostanie tylko stworzenie własnych, klimatycznych wizualizacji. Kazaplan to znacznie więcej niż prosty program do projektowania wirtualnych pokojów\xA0!",
      card_4_desc_3:
        "Możesz również zaoferować tę usługę swoim klientom! Pozwól im zaprojektować pomieszczenie, ustawić meble i udekorować, a następnie wygenerować",
      card_4_desc_4:
        "własne wizualizacje 3D HD, aby w pełni wykorzystać potencjał Twoich produktów.",
      business_examples:
        "Niektóre wizualizacje HD i projekty 360° zaczerpnięte z projektów 3D wykonanych za pomocą oprogramowania do projektowania domu Kazaplan 3D",
      more_information: "Więcej informacji",
      read_more: "Więcej...",
      retail: {
        title:
          "SPRZEDAWCY INTERNETOWI: ZAPREZENTUJ SWOJE PRODUKTY UŻYTKOWNIKOM KOZIKAZA.COM W ICH PROJEKCIE DOMU",
        desc_1:
          "Ponad 80 000 projektów domów miesięcznie dla projektów budowlanych, remontowych i wyposażenia wnętrz. Niezależnie od tego, czy sprzedajesz meble, dekoracje wnętrz czy materiały budowlane, możesz zwiększyć ruch profesjonalnych klientów i zwiększyć przychody dzięki Kazaplan na Kozikaza.com.",
        desc_2:
          "Pozwól naszej agencji 3D modelować Twoje produkty i promować swoją markę w katalogu mebli Kozikaza. W ciągu kilku sekund odwiedzający uzyskają dostęp do opisów produktów w Twoim sklepie internetowym. Po prostu dodają przedmiot do koszyka i sprzedaż masz w kieszeni\xA0!",
        desc_3:
          "Zacznij promować swoje produkty za pomocą bezpłatnego oprogramowania do projektowania domu 3D na Kozikaza.com już dziś\xA0!",
      },
      stand_out: {
        title: "Wyróżnij się na rynku mebli i wyposażenia wnętrz",
        desc: "Oprogramowanie do projektowania domów 3D Kazaplan wyróżni Cię na tle konkurencji na rynku mebli i wyposażenia wnętrz. Niezależnie od tego, czy chcesz zaoferować narzędzie do modelowania 3D wraz z katalogiem online, czy też renderować obrazy swoich produktów w różnych warunkach, to narzędzie jest doskonałą pomocą przy zakupach, która umożliwi szybki wzrost przychodów. To idealne oprogramowanie do projektowania wnętrz dla Ciebie i Twoich klientów",
      },
    },
    material_distributor: {
      title: "KAZAPLAN\xA0: KONFIGURATOR SPRZEDAŻY MATERIAŁÓW BUDOWLANYCH 3D",
      title_2: "Dystrybutorzy materiałów budowlanych i produktów dla domu",
      visibility: "zwiększ swoją widoczność",
      desc_1: "Online – Intuicyjny – Bez zobowiązań",
      card_1_title:
        "ZWIĘKSZ SPRZEDAŻ SWOICH MATERIAŁÓW ZA POMOCĄ KONFIGURATORA",
      card_1_desc_1:
        "Nasz zespół opracowuje konfiguratory 2D i 3D zgodne z Twoimi wymogami bezpośrednio połączone z potężnym narzędziem Kazaplan, które umożliwi Ci sprzedaż paneli ściennych i podłogowych, materiałów budowlanych, instalacji zasilania elektrycznego i tak dalej.",
      card_1_desc_2: "Masz zespół deweloperów\xA0?",
      card_1_desc_3:
        "Mogą skorzystać z naszego API, aby opracować własny, spersonalizowany konfigurator 3D.",
      card_2_title:
        "MASZ MATERIAŁY DEKORACYJNE? ZANURZ SWOICH KLIENTÓW W SALONACH 3D W WIRTUALNEJ RZECZYWISTOŚCI",
      card_2_desc_1:
        "Obrazy 3D są tak realistyczne, że wyglądają jak prawdziwe fotografie. Widoki 360° idą o krok dalej i pozwalają zwiedzić pokój lub cały dom w 3D i wyobrazić sobie w nim siebie.",
      card_2_desc_2:
        "Twórz galerie fotorealistycznych obrazów 3D, aby promować swoje materiały i projekty. Twoje produkty zostaną w pełni wymodelowane przez nasze studio 3D, a następnie możesz je umieścić w różnych nastrojowych sceneriach. Oszczędzaj na studyjnych sesjach zdjęciowych\xA0!",
      card_2_desc_3:
        "Aby pójść jeszcze dalej, Twoi klienci mogą tworzyć wysokiej jakości wizualizacje we własnym projekcie 3D przy użyciu Twoich produktów, dzięki czemu narzędzie to stanowi skuteczną pomoc w sprzedaży.",
      card_3_title: "Pobierz rzut pomieszczenia lub plan domu",
      card_3_desc_1:
        "Klienci mogą stworzyć rzut piętra lub zaprojektować dom i przekazać Ci wszystkie istotne dane",
      card_3_desc_2:
        "Dzięki naszemu API z łatwością uzyskasz dane o wymiarach powierzchni, co pozwoli Ci przygotować kosztorys.",
      business_examples:
        "Niektóre realistyczne wizualizacje 3D zaprojektowane za pomocą narzędzia Kazaplan 3D",
      stand_out: {
        title:
          "Dystrybutorzy materiałów i sklepy z artykułami budowlanymi\xA0: Wybierz narzędzia 2D i 3D dostosowane do Twojej działalności!",
        desc: "Promuj swoje produkty i wznieś się ponad konkurencję dzięki konfiguratorom dostosowanym do Twojej działalności, projektom 2D i 3D oraz renderowaniu obrazu HD. Zwiększ sprzedaż! Zamów wersję demonstracyjną Kazaplan już dziś i uzyskaj jedno z najlepszych narzędzi konfiguracyjnych 3D zaprojektowanych z myślą o wszystkich profesjonalistach budowlanych, które jest łatwe w użyciu i nie wymaga pobierania",
      },
      more_information: "Więcej informacji",
      read_more: "Więcej...",
    },
  },
  pricing: {
    title:
      "ODPOWIEDNIO DO TWOJEJ DZIAŁALNOŚCI I TWOICH POTRZEB W CHMURZE ZNAJDZIESZ CENNIK KAZAPLAN DLA CIEBIE\xA0!",
    title_2: "Oprogramowanie Kazaplan 3D można zintegrować z Twoją stroną bez",
    title_3: "JAKIEKOLWIEK ZOBOWIĄZANIA CZASOWEGOv",
    pricing_nf: "Nie możesz znaleźć cennika odpowiadającego Twoim wymogom:",
    special_offer: "zamów spersonalizowaną ofertę",
    offers: {
      version: "WERSJA",
      include: "W ZESTAWIE\xA0:",
      interested: "Jestem zainteresowany/a\xA0!",
      month: "/Miesiąc",
      internal: {
        title: "ARCHITEKT WEWNĘTRZNY",
        desc: "Potrzebujesz łatwego w obsłudze oprogramowania do prezentacji projektów mieszkań lub domów 2D i 3D dla Twoich zespołów",
        include_1: "Nawet 100 zapisanych projektów miesięcznie",
        price: "299 EUR",
      },
      all: {
        title: "PRAWDZIWY ARCHITEKT",
        desc: "Chcesz, oprócz wersji architekta wewnętrznego, dać swoim klientom możliwość skorzystania z Twoich domów, mieszkań i uzyskania dostępu do ich danych kontaktowych na swojej stronie internetowej",
        include_1: "Nawet 500 zapisanych projektów miesięcznie",
        include_2: "Pozyskiwanie klientów z własnej strony internetowej",
        price: "399 EUR",
      },
      ecommerce: {
        title: "HANDEL INTERNETOWY",
        desc: "Chcesz umożliwić swoim klientom zaprojektowanie domu i umieszczenie w nim mebli w 3D... Sprzedawaj za pomocą tej nowej innowacyjnej platformy!",
        include_1: "Nawet 500 zapisanych projektów miesięcznie",
        include_2: "Pozyskiwanie klientów z własnej strony internetowej",
        include_3: "Integracja listy zakupów",
        include_4: "Funkcja przenoszenia do internetowego koszyka",
        price: "990€",
      },
    },
    pricing_earnings: {
      title:
        "Niezależnie od wybranego cennika, zyskasz, jeśli tylko zechcesz\xA0:",
      check_1: "biblioteka ponad 1000 modnych mebli, paneli i podłóg,",
      check_2:
        "jeden dostęp dla administratora i 5 gości do przeglądania statystyk i zarządzania narzędziem",
      check_3:
        "centrum pomocy z samouczkiem, który pomoże Tobie lub Twoim klientom w korzystaniu z Kazaplan",
    },
    options: {
      title: "Dodatkowe opcje\xA0",
      backup: {
        label:
          "Przekroczyłeś limit planu, który masz w swoim miesięcznym pakiecie\xA0:",
        price: "nie martw się, to tylko 0,50\xA0EUR za dodatkowy zapisany",
      },
      hd: {
        label: "Renderowanie wysokiej jakości\xA0:",
        price:
          "1 EUR\xA0 netto/obraz (możesz obciążyć kosztem swoich klientów i zażądać wybranej opłaty lub zrezygnować z tego",
      },
      view_360: {
        label: "Renderowanie 360\xA0:",
        price: "6 EUR\xA0 netto / Renderowanie 360",
      },
      bo_access: {
        label: "5 dodatkowych dostępów\xA0:",
        price: "50 EUR\xA0netto / Dostęp / miesiąc",
      },
    },
    services: {
      title: "USŁUGI KAZAPLAN\xA0:",
      build: {
        label:
          "Modelowanie 3D domu (bez wyposażenia i ogrodu) przez zespół Kazaplan według szczegółowego projektu\xA0:",
        price: "350 EUR\xA0netto",
      },
      formation: {
        label: "Szkolenie Visio - sesja 2 godzinna\xA0:",
        price: "250 EUR\xA0netto",
      },
      help: {
        label: "Obsługa integracji\xA0:",
        price: "125 EUR\xA0netto / godzinę",
      },
    },
  },
  about: {
    title:
      "Kazaplan jest owocem pracy 30\xA0entuzjastów IT i DIY w firmie Kozikaza",
    desc_1: "Kozikaza to francuski start-up z międzynarodowymi ambicjami.",
    desc_2:
      "Ponad 1\xA0milion projektów jest tworzonych każdego roku w naszym oprogramowaniu 3D, a naszym celem jest pomnożenie tej liczby przez 10 w ciągu 5\xA0lat",
    job_contact: "skontaktuj się z nami w sprawie oferty pracy",
    meetup_contact: "skontaktuj się z nami w celu umówienia spotkania",
    press_release: "KOMUNIKATY PRASOWE\xA0:",
  },
  contact: {
    home_builder: "Budowniczy",
    furniture_distributor: "Dystrybutor mebli",
    material_distributor: "Dystrybutor materiałów",
    other: "Inne",
    title:
      "Twoja firma nie może czekać, skontaktujemy się z Tobą najszybciej, jak to będzie możliwe\xA0!",
    company: "firma",
    country: "kraj",
    first_name: "imię",
    last_name: "nazwisko",
    email: "e-mail",
    phone: "numer telefonu",
    business: "branża",
    message: "wiadomość",
    contact_button: "Skontaktuj się z nami i zamów demo",
    confirmation: "Twoja wiadomość została wysłana",
    error: "Wystąpił błąd. Spróbuj ponownie później",
    contact_mentions:
      "Klikając „Skontaktuj się z nami”, wyrażasz zgodę na nasze warunki. Informacje zebrane z tego formularza podlegają automatycznemu przetwarzaniu, za które odpowiada IDILINK. Więcej informacji można znaleźć w naszych ogólnych warunkach użytkowania (>https://www.kazaplan.com/cgu). Zgodnie z ustawą „Informatique et Libertés” z dnia 6 stycznia 1978 roku z późniejszymi zmianami, masz prawo dostępu, poprawiania i usuwania swoich danych osobowych. Z uzasadnionych powodów możesz również sprzeciwić się przetwarzaniu swoich danych osobowych. Ze swoich praw możesz skorzystać kontaktując się pod adresem: contact@kazaplan.com",
  },
  trust: {
    title: "Już nam zaufali",
  },
  furniture: {
    title: "Ich meble są już na Kozikaza.com",
  },
  contact_us: "Kontakt",
  tou: {
    last_change: "Data ostatniej aktualizacji\xA0:",
  },
  privacy: {
    last_change: "Data ostatniej aktualizacji\xA0:",
  },
  examples: {
    home: "Niektóre realistyczne wizualizacje zaczerpnięte z projektów 3D wykonanych za pomocą oprogramowania do projektowania domu Kazaplan 3D",
    constructor:
      "Niektóre fotorealistyczne wizualizacje zaczerpnięte z projektów 3D wykonanych za pomocą oprogramowania do projektowania domu Kazaplan 3D",
    material:
      "Niektóre realistyczne wizualizacje 3D zaprojektowane za pomocą narzędzia Kazaplan 3D",
    furniture:
      "Niektóre wizualizacje HD i projekty 360° zaczerpnięte z projektów 3D wykonanych za pomocą oprogramowania do projektowania domu Kazaplan 3D",
  },
  kozikaza: {
    link: "https://www.kozikaza.com/pl/",
  },
};

export default translations;
