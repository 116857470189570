import React from "react";
import "./ImageWithOverlay.scss";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageWithOverlay = (props) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <LazyLoadImage
        src={require(`../../assets/img/examples${props.url}`).default}
        alt={props.alt}
      />
      <div className="overlay">
        <div className="text">
          {t(`images.examples.${props.translationKey}`)}
        </div>
      </div>
    </div>
  );
};

export default ImageWithOverlay;
