const translations = {
  images: {
    alt: {
      hp: {
        h_360d: "360 ° HD capture function",
        h_3dhd: "HD capture function",
        carousel_01: "HD capture of a house from the outside",
        carousel_02: "Installation of an armchair on the 3D plan",
        carousel_03: "3D terrain design on the tool",
        example_01: "HD rendering of a black and wood modern bathroom ",
        example_02: "HD rendering of a house with garage and garden",
        example_03: "HD rendering of a white and black design kitchen",
        example_04: "HD capture of a modern dining room",
        features_01: "Photo of a user creating his 2D plan on Kazaplan",
        features_02: "List of furniture and objects available on the tool",
        features_03: "HD capture of a modern living room",
        video: "Video tutorial of the use of the 3D plan",
      },
      furniture: {
        feature_01: "View of the partner brand logo on kazaplan",
        feature_02:
          'Presentation of the "add to cart" function of the Kazaliste',
        feature_03: "Diagram of acquisition of the data entered by the users",
        feature_04: "Examples of HD visuals made on the 3D plane tool",
        ecommerce:
          "Display of the brand of the partner sign on the object presented",
        carousel_01: "3D HD rendering of a modern living room",
        carousel_02: "Choice of furnishings available in Kazaplan's catalog",
        carousel_03: "Layout of a 3D plan on the kazaplan tool",
        example_01:
          "3D plan of single-storey house with Provencal architecture",
        example_02: "2D plan of a family house of 100m2",
        example_03: "3D version of a 100m2 family home",
        example_04:
          "3D visual of a house of 100m2 with a garage and a large garden",
      },
      home_builder: {
        feature_01: "HD captures made on the Kazaplan tool",
        feature_02: "Materials and building elements available on Kazaplan",
        feature_03: "Scheme of qualification of the data entered on Kazaplan",
        carousel_01: "HD rendering of an individual house",
        carousel_02: "3D modelling of a house on Kazaplan",
        carousel_03: "3D Model Plans Gallery",
        example_01: "3D house with a large modern living room",
        example_02: "Modern black and white bathroom plan",
        example_03: "3D visual of a large white and wood kitchen",
        example_04: "Living room modern and bright wood white and grey",
      },
      material_distributor: {
        feature_01: "Design of an electrical plan on Kazaplan",
        feature_02: "Examples of HD captures made on Kazaplan",
        feature_03: "Surface preview table on the 3D tool",
        carousel_01:
          "View of the choice of brick and stone available on the tool",
        carousel_02: "Partner sign chairs available on the 3D plan tool",
        carousel_03: "HD capture of a house plan in 3D",
        example_01: "3D visual of an architect house with a large garden",
        example_02:
          "2D electrical diagram of a house made using our electricity plan configurator",
        example_03: "3D rendering of a bathroom with cement tiles",
        example_04: "2D plane with surface details",
      },
      about: {
        frenchtech: "The French Tech's logo",
        press_adn: "Press article about Kozikaza from L'ADN magazine ",
        press_bfm: "Promotion of Kozikaza on BFM Business Media",
      },
      trust: {
        autourdebb: "ADBB's logo",
        home_builder: "Constructeurdemaison.net's logo",
        lecubefrancais: "Le Cube Français's logo",
        leroymerlin: "Leroy Merlin's logo",
        quotatis: "Quotatis's logo",
      },
    },
    examples: {
      hp: {
        salon_1:
          "Unique contemporary house plan with a garage and a large covered wood terrace. The red bricks of the facade and the slate roof are enhanced by the realism of the 3D visual. ",
        maison_2:
          "House designed by an architect to make the most of the leafy surroundings. The parquet floor and wooden beams on the ceiling give a cosy atmosphere to the black and white interior of this beautiful, high ceilinged modern house.",
        sdb_3:
          "The materials and neat interior design enhance this beautiful 5m² designer bathroom. Its large corner bath, double washbasin unit and lots of storage make it a functional and family-oriented room. ",
        sam_4:
          "3D kitchen with a streamlined and modern design, with white furniture, black countertops and a large dining area. The room is flooded with light coming from the large bay window overlooking the garden.",
      },
      furniture: {
        sam_1:
          "Living room design: 3D rendering of a beautiful living room with a cathedral ceiling that emphasizes the Scandinavian-inspired interior design with its contemporary furniture and décor.",
        sdb_2:
          "Bathroom design: Bathroom plan with a black and wood theme. A large wooden mirror hangs above a double washbasin cabinet, next to bathtub’s glass partition wall.",
        cuisine_3:
          "Kitchen planner: 3D visualization of a large fully-equipped kitchen decorated in a light color scheme, with white furniture and wood worksurfaces. The inclusion of kitchen appliances and decorations makes the scene lifelike.",
        salon_4:
          "Contemporary living and dining area with a dominant wood, white and gray theme. It is decorated and furnished down to the smallest detail and it so realistic that the 3D rendered image could be a real photograph.",
      },
      constructor: {
        maison_1:
          "3D plan of a 90 m² one-story house built in the traditional French Provencal style, complete with garage. The scratched plaster façade of the building and the terracotta roman-tiled roof perfectly complement the covered terrace’s wood pergola",
        plan_2:
          "2D floor plan of a unique 100m² family home. The two bathrooms and the open kitchen that leads onto the light and airy living room make this the perfect home for a family with one or two children. ",
        vueciel_3:
          "3D version of this 100m² family home. The interior design which includes contemporary furniture and trendy décor enhances the house and makes it easy to project.",
        maison_4:
          "100m² house with a converted attic, garage and large garden. The exterior is rendered in 3D with a beautiful wood terrace, a garden shed and kids’ games. It already feels like home…",
      },
      material: {
        ext_1:
          "Beautiful house designed by an architect to make the most of the leafy surroundings. The composite cladding on the exterior and the awning covering the outdoor terrace make this a unique property.",
        elec_2:
          "A house’s electrical diagram in 2D created with our circuit diagram configurator. All electrical components are featured: sockets, switches, light spots and so on. Here, the electric cable connections are also shown.  ",
        sdb_3:
          "3D rendering of a bathroom featuring a boat deck style parquet floor. On the bathtub platform, the tiles and ceramic have been worked in a retro and chic style",
        plan_4:
          "All the 2D plans feature the detailed dimensions of each room, making it easy to obtain the surface areas and precise measurements for the whole house and its exterior.",
      },
    },
  },
  seo: {
    home: {
      title: "Professional 3D home design software online - Kazaplan.com",
      description:
        "Kazaplan is a professional 2D 3D plan software that allows you to model houses and apartments in just a few clicks. Kazaplan boosts your business of building houses, furniture distribution and materials.",
    },
    business: {
      furniture_distributor: {
        title:
          "3D and Virtual Reality (VR) for sale of furniture and decoration - Kazaplan.com",
        description:
          "Boost your online sales thanks to 3D and virtual reality of Kazaplan software. Help your customers imagine their houses with your products 3D models",
      },
      material_distributor: {
        title: "Building materials sales 3D configurator - Kazaplan.com",
        description:
          "Sell your wall and floor coverings, building materials, electrical supplies with our online 2D / 3D configurator. Your clients trace a floorplan or design a house and pass on all the relevant data to you.",
      },
      home_builder: {
        title:
          "3D Architecture Software for individual home builders - Kazaplan.com",
        description:
          "Kazaplan 3D architectural software will assist you with the sale of your houses at every step of the process thanks to a complete 2D and 3D design solution",
      },
    },
    pricing: {
      title: "Pricing - Kazaplan.com",
    },
    about: {
      title: "About us - Kazaplan.com",
    },
    contact: {
      title: "Request a demo - Kazaplan.com",
    },
  },
  route: {
    "individual-home-builders": "individual-home-builders",
    "furniture-distributor": "furniture-distributor",
    "building-material-distributor": "building-material-distributor",
    tool: {
      "privacy-policy": "tool/privacy-policy",
      "terms-of-use": "tool/terms-of-use",
      "terms-of-sales": "tool/terms-of-sales",
    },
    website: {
      "privacy-policy": "website/privacy-policy",
      "terms-of-use": "website/terms-of-use",
      "terms-of-sales": "website/terms-of-sales",
    },
    demo: "demo",
    pricing: "pricing",
    api: "documentation-api",
    about: "about-us",
    "legal-notice": "legal-notice",
    backup: "backup-plans",
  },
  button: {
    ask_demo: "Request a demo",
    ask_demo_ext: "Request Kazaplan demo",
    personnal_user: "I'm individual user",
    explore_solution: "Explore the solution",
    pricing: "Pricing",
    discover: "Learn more about Kozikaza",
    try_3D: "Want to try the 3D design software\xA0?",
  },
  labels: {
    "360_views": "360° views",
    hd_image: "HD image",
  },
  navbar: {
    menu: {
      home: "Home",
      business: {
        home: "For your business",
        home_builder: "Home builder",
        furniture_distributor: "Furniture distributor",
        material_distributor: "Material distributor",
      },
      pricing: "Pricing",
    },
  },
  footer: {
    api: "Documentation API",
    about: "About US",
    tou: "TOU",
    tos: "TOS",
    privacy: "Privacy policy",
    follow: "Follow us",
  },
  home: {
    kzp: "Kazaplan",
    title: "the professional 3D home design software",
    title_2: "that will boost business directly on your website",
    kzpintro:
      "Kazaplan is the professional 3D modeling software which allows you to design houses and apartments with a few easy clicks.",
    feature_1_head: "High performance",
    feature_2_head: "Intuitive",
    feature_3_head: "100% online",
    feature_1:
      "Fast and with a wide range of features, it allows you to create 3D house plans in no time.",
    feature_2:
      "Not just for architects, the software has been designed for all types of user.",
    feature_3:
      "No need to download the software, the plans are accessible on your browser.",
    constructor: {
      title: "Individual home builders",
      description: `<strong>Promote your offer, attract clients and help your sales teams sell your properties all thanks to 3D!</strong>
          A new home holds the promise of a new life for home buyers. Put all chances on your side with this carefully-conceived sales tool.
          Whether you provide individually designed houses or have a catalogue of existing properties, designing plans and blueprints with Kazaplan will be made easier. The perfect blueprint maker for your clients’ projects, Kazaplan allows you to design every house model in 3D\xA0: allotments, contemporary or traditional designs, flat roofed houses, single-storey houses or wooden ones!`,
    },
    decoration_distributor: {
      title: "Furniture and home decoration distributors",
      description: `<strong>Sell your furniture directly on your site thanks to virtual reality.</strong>
          When taking part in an interior design project, your clients need to picture themselves in their future homes and imagine their interior furnished with your products.
          With Kazaplan they will be able to design their own virtual house and choose the floor plan and layout of each room in their own time. The 3D planner tool will allow them to get a feel of the proportions and textures, as well as the colors of each piece of furniture, light fixture and decorative object. It’s as if they were there!`,
    },
    material_distributor: {
      title: "Material distributors and home improvement stores",
      description: `<strong>Enhance your offer and sell your materials with customized configurators integrated into your site.</strong>
          When taking part in a building or renovation project, your clients must be able to picture the result and estimate the costs involved so they can choose their materials. Attractive visuals will highlight your products.
          A 2D or 3D configurator that integrates your business rules will allow your client to project, whatever your line of business. With Kazaplan everything is possible: create a 3D kitchen or 3D bathroom, show an electrical diagram or even try out different floor and wall coverings.`,
    },
    plan2d: {
      title: "Draw a 2D plan",
      description: `With our fast and easy-to-use 3D software, a few minutes is all it takes to design the 2D floor plan of a room or the blueprint of a whole house.
          Start by drawing your walls and partitions with the user-friendly room planner, add more levels and you can then place your doors, windows and staircases, and even trace your attic and under-eave spaces with a few easy clicks.<br /><br />
          Say goodbye to complex 3D architecture software reserved for study offices and choose the Kazaplan home design software: a 3D house plan tool designed with everyone in mind.`,
      want_it: "Want to try the architecture<br />software?",
    },
    plan3d: {
      title: "Furnish and decorate your 3D plan",
      description: `Conceive the interior design of your home by integrating your own furniture and decorative objects rendered in 3D.<br />
          Browse through our ever-growing catalogue and choose from over 2500 3D objects to virtually furnish and decorate the interior and exterior of your house with contemporary furniture, the latest interior design, electric devices and household appliances.`,
      want_it: "Want to try the 3D interior<br />design software?",
    },
    planHD: {
      title: "From 3D model to realistic high definition rendering",
      desc_1:
        "Within minutes, your client or your collaborator will receive the 3D HD rendering or 360° view of the room in their inbox",
      desc_2: `Once the 3D model complete, a couple of clicks will generate a 3D image so lifelike it looks like a photograph. And then? You can begin the virtual tour of the house and visualize it as if you were actually there. There’s no better way`,
      want_it: "Want to try the 3D<br />modelling software?",
    },
    examples: {
      title:
        "Some lifelike visuals taken from 3D plans designed with the Kazaplan 3D home planner software",
      more_information: "More information",
    },
    prices: {
      title:
        "Whether your business involves building individual homes, furniture retail or building materials distribution, our 3D modelling software will assist you and fit in perfectly with your professional tools.",
      description:
        "The design of 2D and 3D plans, the business configurators, the 3D virtual tour and the high definition visuals will enhance your product offer - all are powerful tools that will set you apart from the competition. Start today and ask for your Kazaplan demo: one of the best 3D home design tools, easy to use and fully online. It was conceived for all professionals working in home building and interior design.",
    },
    discover: {
      title:
        "<strong>Are you a individual user</strong> or <strong>an interior designer</strong>\xA0?",
      description:
        "Visit Kozikaza.com where you can find the Kozikaza 3D house planner software available free of charge, as well as a community of individual users involved in home building, renovation and refurbishment projects. Over one million home plans are drawn up every year on Kozikaza.com.",
    },
  },
  business: {
    home_builder: {
      title: "Kazaplan, the 3D architecture software",
      title_2: "for individual home builders",
      sell_more: "sell more homes\xA0!",
      desc_1: "Online - Easy to use – Without obligation",
      desc_2:
        "The Kazaplan 3D architectural software will assist you with the sale of your houses at every step of the process thanks to a complete 2D and 3D design solution.",
      check_1: "Promote your house catalogue ",
      check_2: "Offer a sales aid tool to your sales team",
      check_3:
        "Attract more prospective clients that have a home building project",
      card_1_title:
        "PROMOTE YOUR HOUSES AND INCREASE YOUR SALES CONVERSION RATE\xA0!",
      card_1_desc_1:
        "Present your preliminary drafts to your prospective clients with lifelike 3D images or 360° views.",
      card_1_desc_2:
        "Show them homes furnished with contemporary furniture and trendy interior design, and then offer them a virtual tour of the house. Each room can be visited, as if your clients were there. All these solutions supplied by our home architecture software will allow you to promote and showcase your projects. High definition 3D images will enhance your homes and the 3D virtual tour will allow potential buyers to picture themselves in their new house.",
      card_2_title: "SAVE TIME DURING THE PRELIMINARY DRAFT PHASE",
      card_2_desc_1: "Do you have a home catalogue\xA0?",
      card_2_desc_2:
        "Make 2D and 3D models of your houses. Then let your sales force adapt the interior and exterior of these models to match the needs of their prospective clients, using our catalogue of 3D objects containing furniture, home decoration and coverings. The tool is now a piece of interior design software.",
      card_2_desc_3: "Do you custom-build houses\xA0?",
      card_2_desc_4:
        "Unclutter your design office by providing your sales team with easy to use software",
      card_2_desc_5:
        "that enables them to draw a 3D home plan from beginning to end. Once the architectural design complete, they can furnish and decorate the plan in just a few minutes.",
      card_2_desc_6:
        "An adapted 3D plan allows you to show clients different options and choose quickly between two solutions.",
      card_2_desc_7:
        "The Kazaplan 3D home building software is the perfect solution to reach your goal of enabling your clients to picture themselves in their new interior with the 3D rendering of their home.",
      card_2_want_it: "Want to try the house building software\xA0?",
      card_3_title: "COLLECT THE CONTACT DETAILS OF VISITORS’ TO YOUR SITE",
      card_3_desc_1:
        "Unlike other downloadable architecture software such as Cedreo or Miao, your clients can freely use our 3D home building software to fine-tune their designs.",
      card_3_desc_2:
        "Allow visitors on your site to draw their 3D home plan easily or adapt the ones already in your house catalogue directly on your website, thanks to the many advanced and configurable features.",
      card_3_desc_3:
        "With the Kazaplan 3D home design software, your prospective clients can personalize the house models on offer: with just a few clicks they can access",
      card_3_desc_4:
        "the interactive floor plans, arrange furniture and fully decorate the interior and exterior of the house. The software then works as an interior design tool. Your sales force now has all the information needed to contact and assist your clients, from their required type of construction and the number of rooms, to their layout and furnishings.",
      card_3_want_it: "Want to try the 3D building design software",
      business_examples:
        "Some photorealistic visuals taken from 3D plans designed with the Kazaplan 3D home planner software",
      stand_out: {
        title:
          "Individual home builders and construction professionals: use our 3D architecture software to increase your sales.",
        desc: "The modeling and visualization of the 2D and 3D house plans, the virtual tour and the photorealistic images will showcase your offer in an innovative way. Set yourself apart from the competition! Ask for your Kazaplan demo today: one of the most advanced 3D design tools, intuitive and with no downloading. It was designed with all construction and building professionals in mind.",
      },
      more_information: "More information",
      read_more: "More...",
    },
    furniture_distributor: {
      title: "KAZAPLAN\xA0: USE VIRTUAL REALITY TO SELL",
      title_2: "FURNITURE AND INTERIOR DECORATION ONLINE",
      title_3: "for suppliers, pure players and distributors",
      desc_1:
        "3D software available on your site – 3D agency at your disposal – Without obligation",
      card_1_title: "YOUR FURNITURE FEATURED IN YOUR CLIENT’S 3D INTERIOR",
      card_1_desc_1:
        "Within minutes, visitors to your website will be able to furnish their 3D interior with items from your 3D furniture and decoration catalogue. With the furniture layout tool, Kazaplan is a purchasing aid that will enable visitors imagine your products in their 3D home interior.",
      card_1_want_it: "Want to try the 3D interior design software\xA0?",
      card_2_title: "INCREASE YOUR AVERAGE ORDER AMOUNT WITH 3D",
      card_2_desc_1: "Encourage bulk or impulse buying!",
      card_2_desc_2:
        "With your 3D furniture catalogue, clients can mix and match items from your collection to create a unique ambiance within their 3D interior. The 3D viewing is a significant cross selling tool. Use our furniture arrangement tool to increase your sales\xA0!",
      card_2_desc_3:
        "Whether you work with Magento, prestashop or any other CMS, with just one easy click our API will allow your clients to put the list of Kazaplan items into their shopping basket on your ecommerce website\xA0!",
      card_3_title: "COLLECT THE CONTACT DETAILS OF VISITORS TO YOUR SITE",
      card_3_desc_1:
        "With Kazaplan your clients can design their living room, kitchen, closets and bedroom in 3D. Then is the perfect time to contact them and talk about their project! Kazaplan can equally be plugged-into your CRM tool thanks to our API, allowing you to develop lead nurturing.",
      card_3_desc_2:
        "Clients furnish their plans with your furniture > register their plans along with their contact details > lead nurturing",
      card_4_title:
        "IMMERSE YOUR CLIENTS IN VIRTUAL SHOWROOMS WITH 3D RENDERING AND 360° VIEWS",
      card_4_desc_1:
        "The 3D HD renderings are so lifelike that they look like real photographs. The 360° viewing makes it possible to view the whole room, as if you were there!",
      card_4_desc_2:
        "Create galleries for the photorealistic renderings and promote your products. Create different atmospheres in 3D and save money by reducing the need for a photography studio! Our 3D studio will fully take care of the modelling of your products into 3D objects, it’s then up to you to create your own atmospheric visuals. Kazaplan is much more than a simple virtual room designer\xA0!",
      card_4_desc_3:
        "You can also offer this service to your clients! Enable them to design the room, arrange the furniture and decorate, then generate",
      card_4_desc_4:
        "their own 3D HD visuals to get the full potential of your products.",
      business_examples:
        "Some HD visuals and 360° plans designed with the Kazaplan 3D home planner software",
      more_information: "More information",
      read_more: "More...",
      retail: {
        title:
          "ONLINE RETAILERS: SHOWCASE YOUR PRODUCTS TO PERSONAL USERS USING KOZIKAZA.COM FOR THEIR HOME PROJECT",
        desc_1:
          "Over 80 000 house plans a month for building, renovation and interior decoration projects. Whether you sell furniture, interior decoration or building materials you can drive more qualified traffic and increase income with Kazaplan on Kozikaza.com.",
        desc_2:
          "Let our 3D agency model your products and promote your brand in the Kozikaza furniture catalogue. In just a few seconds, visitors will have access to the product descriptions on your online store. They simply add the item to their shopping basket and it’s a sale\xA0!",
        desc_3:
          "Start promoting your products on the free 3D home planner software on Kozikaza.com today\xA0!",
      },
      stand_out: {
        title: "Stand out in the furniture and interior design market",
        desc: "The Kazaplan 3D home design software will set you apart from the competition in the furniture and interior design market. Whether you want to offer a 3D modelling tool alongside your online catalogue or render images of your products in atmospheric settings, this tool is a great purchasing aid that will enable your revenue to increase rapidly. This is the perfect interior design software for you and your clients.",
      },
    },
    material_distributor: {
      title: "KAZAPLAN\xA0: THE 3D BUILDING MATERIALS SALES CONFIGURATOR",
      title_2: "For building material distributors and home improvement stores",
      visibility: "increase your visibility",
      desc_1: "Online – Intuitive – Without obligation",
      card_1_title: "BOOST SALES OF YOUR MATERIALS WITH A CONFIGURATOR",
      card_1_desc_1:
        "Our team develops 2D and 3D configurators with your business rules directly connected into the powerful Kazaplan tool, which will enable you to sell your wall and floor coverings, building materials, electrical supplies and so on. ",
      card_1_desc_2: "Do you have a team of developers\xA0?",
      card_1_desc_3:
        "They can use our API to develop your own customized 3D configurator.",
      card_2_title:
        "DO YOU HAVE DECORATIVE MATERIALS? IMMERSE YOUR CLIENTS IN THE 3D SHOWROOMS WITH VIRTUAL REALITY",
      card_2_desc_1:
        "The 3D images are so lifelike that they replace photographs. Les 360° views take it one step further and allow you to visit a room or an entire home in 3D and picture yourself in it.",
      card_2_desc_2:
        "Create galleries of photorealistic 3D images to enhance your materials and designs. Your products will be fully modelled by our 3D studio, and you can then place them in different atmospheric settings. Save money on studio photo shoots\xA0!",
      card_2_desc_3:
        "To take things even further, your clients can create these high-quality visuals on their own 3D plan and with your products, making this tool an effective sales aid.",
      card_3_title: "Retrieve a room’s floorplan or a house plan with ease",
      card_3_desc_1:
        "Your clients trace a floorplan or design a house and pass on all the relevant data to you.",
      card_3_desc_2:
        "Thanks to our API, you will easily be able to retrieve the surface dimensions enabling you to calculate your estimate.",
      business_examples:
        "Some lifelike 3D visuals designed with the Kazaplan 3D tool",
      stand_out: {
        title:
          "Materials distributors and home improvement [DIY] stores\xA0: Choose 2D and 3D tools adapted to your business!",
        desc: "Promote your products and rise above the competition with configurators adapted to your business, 2D and 3D plans and HD image rendering. Boost your sales! Ask for a Kazaplan demo today, and get one of the best 3D configuration tools designed with all building professionals in mind, easy-to-use and with no download.",
      },
      more_information: "More information",
      read_more: "More...",
    },
  },
  pricing: {
    title:
      "ACCORDING TO YOUR BUSINESS AND YOUR NEEDS, THERE IS A KAZAPLAN CLOUD PRICE PLAN MADE FOR YOU\xA0!",
    title_2: "The Kazaplan 3D software can be integrated on your site without",
    title_3: "ANY COMMITMENT OF DURATION",
    pricing_nf: "You can not find a price plan that corresponds to your use:",
    special_offer: "Request a personalized offer",
    offers: {
      version: "VERSION",
      include: "INCLUDED\xA0:",
      interested: "I'm interested\xA0!",
      month: "/Month",
      internal: {
        title: "INTERNAL ARCHITECT",
        desc: "You want easy-to-use software for presenting 2D and 3D apartment or house projects for your teams",
        include_1: "Up to 100 saved plans per month",
        price: "299€",
      },
      all: {
        title: "COMPLETE ARCHITECT",
        desc: "You want, in addition to the internal architect version, to give your customers the opportunity to appropriate your houses, apartments and catch their contact details on your website",
        include_1: "Up to 500 saved plans per month",
        include_2: "Lead capture from your website",
        price: "399€",
      },
      ecommerce: {
        title: "E-COMMERCE",
        desc: "You want to allow your customers to draw their home and integrate your furniture in 3D ... Sell through this new innovative lever!",
        include_1: "Up to 500 saved plans per month",
        include_2: "Lead capture from your website",
        include_3: "Integration of the shopping list",
        include_4: "Transmission functionality to your web shopping cart",
        price: "990€",
      },
    },
    pricing_earnings: {
      title: "Whatever the price plan chosen, you benefit if you wish\xA0:",
      check_1:
        "a library of more than 1,000 fashion furniture, cladding & flooring,",
      check_2:
        "one Back office administrator access and 5 guests access to view the statistics and manage the tool",
      check_3:
        "a tutorial help center to help you or your customers to use Kazaplan",
    },
    options: {
      title: "Additional options\xA0:",
      backup: {
        label:
          "You exceed the plan limit you have in your monthly package\xA0:",
        price: "don’t worry it’s only 0,50\xA0€ per additional saved plan",
      },
      hd: {
        label: "High quality rendering production\xA0:",
        price:
          "1€\xA0excl tax / picture (you can bill it or not to your customers, and the price you want)",
      },
      view_360: {
        label: "360 rendering production\xA0:",
        price: "6€\xA0excl tax / 360 Rendering",
      },
      bo_access: {
        label: "5 additional back office access\xA0:",
        price: "50€\xA0excl tax / Access / month",
      },
    },
    services: {
      title: "KAZAPLAN SERVICES\xA0:",
      build: {
        label:
          "3D Modeling of a house (excluding furnishings and garden) by the Kazaplan team according to a detailed plan\xA0:",
        price: "350€\xA0excl tax",
      },
      formation: {
        label: "Visio training per session of 2 hours\xA0:",
        price: "250€\xA0excl tax",
      },
      help: {
        label: "Integration support\xA0:",
        price: "125€\xA0excl tax / Hour",
      },
    },
  },
  about: {
    title:
      "Kazaplan is the fruit of 30\xA0IT & DIY enthusiasts grouped within the Kozikaza company",
    desc_1: "Kozikaza is a french start-up with international ambitions.",
    desc_2:
      "More than 1\xA0million plans are created each year on our 3D planner and our target is to multiply this number by 10 in 5\xA0years",
    job_contact: "contact us for a job",
    meetup_contact: "contact us for an appointment",
    press_release: "PRESS RELEASES\xA0:",
  },
  contact: {
    home_builder: "Home builder",
    furniture_distributor: "Furniture distributor",
    material_distributor: "Material distributor",
    other: "Other",
    title:
      "Your business doesn’t wait, we’ll get back to you as soon as possible\xA0!",
    company: "company",
    country: "country",
    first_name: "first name",
    last_name: "last name",
    email: "email",
    phone: "phone number",
    business: "business",
    message: "message",
    contact_button: "Contact us and request a demo",
    confirmation: "Your message has been sent",
    error: "An error occured, please try again later",
    contact_mentions:
      'By clicking on "Contact us" you agree to our terms and conditions. The informations gathered from this form are subject to an automated treatment of which IDILINK is responsible for. For more information, please refer to our terms and conditions (>https://www.kazaplan.com/cgu). In accordance with the law "Informatique et Libertés" of 6 January 1978 as amended, you have the right to access, rectify and delete personal data concerning you. For legitimate reasons, you can also oppose the processing of data concerning you. You can exercise your rights by contacting: contact@kazaplan.com',
  },
  trust: {
    title: "Already trusted by",
  },
  furniture: {
    title: "Their furniture is already on Kozikaza.com",
  },
  contact_us: "Contact us",
  tou: {
    last_change: "Last update date\xA0: ",
  },
  privacy: {
    last_change: "Last update date\xA0: ",
  },
  examples: {
    home: "Some lifelike visuals taken from 3D plans designed with the Kazaplan 3D home planner software",
    constructor:
      "Some photorealistic visuals taken from 3D plans designed with the Kazaplan 3D home planner software",
    material: "Some lifelike 3D visuals designed with the Kazaplan 3D tool",
    furniture:
      "Some HD visuals and 360° plans designed with the Kazaplan 3D home planner software",
  },
  kozikaza: {
    link: "https://www.kozikaza.com/en/",
  },
};

export default translations;
