import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Icon from "../Icon";

import "./Pricing.scss";

class Pricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  toggleClasses(index) {
    if (!this.state.active) {
      this.setState({ active: index });
    } else {
      this.setState({ active: false });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <section
        className={`kz-pricing--versions ${
          this.state.active ? "kz-pricing--active" : ""
        }`}
      >
        <div
          className={`kz-pricing--version kz-pricing--version-internal ${
            this.state.active === "internal" ? "kz-pricing--active" : ""
          }`}
        >
          <Icon value="price-1" />
          <h3>
            <span>{t("pricing.offers.version")}</span>{" "}
            {t("pricing.offers.internal.title")}
          </h3>
          <p>{t("pricing.offers.internal.desc")}</p>

          <div className="kz-pricing--version-included">
            <strong>{t("pricing.offers.include")}</strong>
            <ul>
              <li>
                <Icon value="check-green" />
                <p>{t("pricing.offers.internal.include_1")}</p>
              </li>
            </ul>
          </div>

          <p className="kz-pricing--version-price">
            <strong>{t("pricing.offers.internal.price")}</strong>{" "}
            {t("pricing.offers.month")}
          </p>
          <a href="demo" className="kz-button kz-button--tools kz-button--flat">
            <span>{t("pricing.offers.interested")}</span>
          </a>
          <button
            className="kz-pricing--version-toggle"
            onClick={() => this.toggleClasses("internal")}
          >
            <span>&times;</span>
          </button>
        </div>

        <div
          className={`kz-pricing--version kz-pricing--version-complete ${
            this.state.active === "complete" ? "kz-pricing--active" : ""
          }`}
        >
          <Icon value="price-2" />
          <h3>
            <span>{t("pricing.offers.version")}</span>{" "}
            {t("pricing.offers.all.title")}
          </h3>
          <p>{t("pricing.offers.all.desc")}</p>

          <div className="kz-pricing--version-included">
            <strong>{t("pricing.offers.include")}</strong>
            <ul>
              <li>
                <Icon value="check-green" />
                <p>{t("pricing.offers.all.include_1")}</p>
              </li>
              <li>
                <Icon value="check-green" />
                <p>{t("pricing.offers.all.include_2")}</p>
              </li>
            </ul>
          </div>

          <p className="kz-pricing--version-price">
            <strong>{t("pricing.offers.all.price")}</strong>{" "}
            {t("pricing.offers.month")}
          </p>
          <a href="demo" className="kz-button kz-button--tools kz-button--flat">
            <span>{t("pricing.offers.interested")}</span>
          </a>
          <button
            className="kz-pricing--version-toggle"
            onClick={() => this.toggleClasses("complete")}
          >
            <span>&times;</span>
          </button>
        </div>

        <div
          className={`kz-pricing--version kz-pricing--version-ecommerce ${
            this.state.active === "ecommerce" ? "kz-pricing--active" : ""
          }`}
        >
          <Icon value="price-3" />
          <h3>
            <span>{t("pricing.offers.version")}</span>{" "}
            {t("pricing.offers.ecommerce.title")}
          </h3>
          <p>{t("pricing.offers.ecommerce.desc")}</p>

          <div className="kz-pricing--version-included">
            <strong>{t("pricing.offers.include")}</strong>
            <ul>
              <li>
                <Icon value="check-white" />
                <p>{t("pricing.offers.ecommerce.include_1")}</p>
              </li>
              <li>
                <Icon value="check-white" />
                <p>{t("pricing.offers.ecommerce.include_2")}</p>
              </li>
              <li>
                <Icon value="check-white" />
                <p>{t("pricing.offers.ecommerce.include_3")} </p>
              </li>
              <li>
                <Icon value="check-white" />
                <p>{t("pricing.offers.ecommerce.include_4")}</p>
              </li>
            </ul>
          </div>

          <p className="kz-pricing--version-price">
            <strong>{t("pricing.offers.ecommerce.price")}</strong>{" "}
            {t("pricing.offers.month")}
          </p>
          <a href="demo" className="kz-button kz-button--flat kz-button--dark">
            <span>{t("pricing.offers.interested")}</span>
          </a>
          <button
            className="kz-pricing--version-toggle"
            onClick={() => this.toggleClasses("ecommerce")}
          >
            <span>&times;</span>
          </button>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Pricing);
