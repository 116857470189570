import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "./Furniture.scss";

class Furniture extends Component {
  render() {
    const { t } = this.props;

    return (
      <section className="kz-furniture">
        <h2>{t("furniture.title")}</h2>
        <ul>
          <li>
            <img
              src={require("../../assets/img/furniture/made.png").default}
              loading="lazy"
              alt="Made"
            />
          </li>
          <li>
            <img
              src={require("../../assets/img/furniture/laredoute.png").default}
              loading="lazy"
              alt="La Redoute"
            />
          </li>
          <li>
            <img
              src={
                require("../../assets/img/furniture/maisonsdumonde.png").default
              }
              loading="lazy"
              alt="Maisons du monde"
            />
          </li>
          <li>
            <img
              src={require("../../assets/img/furniture/drawer.png").default}
              loading="lazy"
              alt="Drawer"
            />
          </li>
          <li>
            <img
              src={
                require("../../assets/img/furniture/lightonline.png").default
              }
              loading="lazy"
              alt="lightonline.fr"
            />
          </li>
          <li>
            <img
              src={require("../../assets/img/furniture/decoclico.png").default}
              loading="lazy"
              alt="decoclico.fr"
            />
          </li>
          <li>
            <img
              src={
                require("../../assets/img/furniture/madeinmeubles.png").default
              }
              loading="lazy"
              alt="made-in-meubles.com"
            />
          </li>
        </ul>
      </section>
    );
  }
}

export default withTranslation()(Furniture);
