import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import cn from "classnames";

import { DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES } from "../../config";
import { getNavbarTradLinks, getTranslatedRoute } from "../../i18n";

import Icon from "../Icon";

import "./NavBar.scss";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      burgerMenuActive: false,
      langMenuActive: false,
      languages: [],
    };

    this.toggleBurgerMenu = this.toggleBurgerMenu.bind(this);
    this.toggleLangMenu = this.toggleLangMenu.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidMount() {
    const i18nNav = getNavbarTradLinks(this.props.i18n.language);
    this.setState({
      languages: i18nNav,
    });
  }

  toggleBurgerMenu() {
    this.setState({ burgerMenuActive: !this.state.burgerMenuActive });
  }

  toggleLangMenu() {
    this.setState({ langMenuActive: !this.state.langMenuActive });
  }

  changeLanguage(lng) {
    const { history } = this.props;
    // i18n.changeLanguage(lng)
    history.push({
      pathname: lng === DEFAULT_LANGUAGE ? "/" : `/${lng}`,
    });

    this.setState({
      langMenuActive: false,
    });
  }

  render() {
    const {
      t,
      i18n,
      style,
      innerRef,
      match: {
        params: { locale },
      },
    } = this.props;
    const homeLocation = i18n.language === "fr" ? "/" : `/${i18n.language}`;
    const prefix = locale ? `/${locale}` : "";

    return (
      <header
        className={cn("kz-header", {
          "kz-header--open": this.state.burgerMenuActive,
        })}
        style={style}
        ref={innerRef}
      >
        <div className="kz-header-container">
          <button className="kz-header--burger" onClick={this.toggleBurgerMenu}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>

          <a href={homeLocation} className="kz-header--logo">
            <Icon value="logo" />
          </a>

          <nav className="kz-header--nav">
            <ul className="kz-header--mainnav">
              <li>
                <a href={homeLocation} className="navbar-item">
                  {t("navbar.menu.home")}
                </a>
              </li>
              <li>
                <span className="navbar-item">
                  {t("navbar.menu.business.home")}
                </span>
                <ul className="kz-header--subnav">
                  <li>
                    <NavLink
                      to={`${prefix}/${t("route.individual-home-builders")}`}
                    >
                      <Icon value="house-black" />{" "}
                      {t("navbar.menu.business.home_builder")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${prefix}/${t("route.furniture-distributor")}`}
                    >
                      <Icon value="materials-black" />{" "}
                      {t("navbar.menu.business.furniture_distributor")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`${prefix}/${t(
                        "route.building-material-distributor"
                      )}`}
                    >
                      <Icon value="furniture-black" />{" "}
                      {t("navbar.menu.business.material_distributor")}
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to={`${prefix}/${t("route.pricing")}`}
                  className="navbar-item"
                >
                  {t("navbar.menu.pricing")}
                </NavLink>
              </li>
            </ul>

            <div className="kz-header--ctas">
              <NavLink
                to={`${prefix}/${t("route.demo")}`}
                className="kz-button kz-button--tools kz-button--flat"
              >
                <span>{t("button.ask_demo")}</span>
              </NavLink>
              <a
                href={
                  i18n.language === DEFAULT_LANGUAGE
                    ? "/#kozikaza"
                    : `/${i18n.language}#kozikaza`
                }
                rel="noopener noreferrer"
                className="kz-button kz-button--secondary"
              >
                <span>{t("button.personnal_user")}</span>
              </a>
            </div>
          </nav>

          <div
            className={cn("kz-header--lang", {
              "kz-header--lang--open": this.state.langMenuActive,
            })}
          >
            <button
              className="kz-header--lang-select"
              onClick={this.toggleLangMenu}
            >
              {i18n.language}
              <Icon value="arrow-down" />
            </button>
            <ul className="kz-header--lang-list">
              {Object.keys(this.state.languages).map((currentLng) => {
                return (
                  <li key={currentLng}>
                    <a
                      href={getTranslatedRoute(
                        t,
                        i18n,
                        this.props.location.pathname,
                        currentLng
                      )}
                      data-lng={currentLng}
                      onClick={(event) => {
                        const lng = event.target.dataset.lng;
                        if (AVAILABLE_LANGUAGES.indexOf(lng) !== -1) {
                          const [, domain, ext] =
                            window.location.host.split(".");
                          const expires = new Date(Date.now() + 86400e3 * 30);
                          document.cookie = `_locale=${lng}; path=/; domain=${domain}.${ext}; expires=${expires.toUTCString()}`;
                        }
                      }}
                    >
                      {this.state.languages[currentLng]}
                      <span>{currentLng}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(withTranslation()(NavBar));
