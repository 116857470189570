import React from "react";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { hydrate, render } from "react-dom";
import TagManager from "react-gtm-module";

import * as serviceWorker from "./serviceWorker";

import "./commons.scss";
import App from "./App";

import "./i18n";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_ENV
};

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

serviceWorker.unregister();
