import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";

import About from "./views/About";
import HomeBuilder from "./views/Business/HomeBuilder";
import FurnitureDistributor from "./views/Business/FurnitureDistributor";
import MaterialDistributor from "./views/Business/MaterialDistributor";
import Contact from "./views/Contact";
import Home from "./views/Home";
import Pricing from "./views/Pricing";
import NotFound from "./views/NotFound";
import ToolTou from "./views/Solution/Tou";
import ToolTos from "./views/Solution/Tos";
import ToolPrivacyPolicy from "./views/Solution/PrivacyPolicy";
import WebsiteTou from "./views/Website/Tou";
import WebsiteTos from "./views/Website/Tos";
import WebsitePrivacyPolicy from "./views/Website/PrivacyPolicy";
import Backup from "./views/Backup";
import Legal from "./views/Legal";

import { ScrollToTop } from "./components";

import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "./config";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/individual-home-builders",
    component: HomeBuilder,
  },
  {
    path: "/furniture-distributor",
    component: FurnitureDistributor,
  },
  {
    path: "/building-material-distributor",
    component: MaterialDistributor,
  },
  {
    path: "/pricing",
    component: Pricing,
  },
  {
    path: "/demo",
    component: Contact,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/legal-notice",
    component: Legal,
  },
  {
    path: "/website/terms-of-use",
    component: WebsiteTou,
  },
  {
    path: "/website/terms-of-sales",
    component: WebsiteTos,
  },
  {
    path: "/website/privacy-policy",
    component: WebsitePrivacyPolicy,
  },
  {
    path: "/tool/terms-of-use",
    component: ToolTou,
  },
  {
    path: "/tool/terms-of-sales",
    component: ToolTos,
  },
  {
    path: "/tool/privacy-policy",
    component: ToolPrivacyPolicy,
  },
  {
    path: "/backup",
    component: Backup,
  },
];

const Routes = (routeProps) => {
  const { t, i18n } = useTranslation();
  const {
    match: {
      params: { locale = DEFAULT_LANGUAGE },
    },
  } = routeProps;
  if (i18n.language !== locale) {
    i18n.changeLanguage(locale);
  }
  return (
    <Switch>
      {routes.map((route, i) => {
        const routeKeys = [route.path.replace(/^\/*(.+?)\/*?$/, "$1")].map(
          (k) => (k === "/" ? "" : k)
        );
        return (
          <Route
            exact={route.path === "/"}
            key={`route-${i}`}
            path={`/:locale(${AVAILABLE_LANGUAGES.filter(
              (l) => l !== DEFAULT_LANGUAGE
            ).join("|")})?/${routeKeys.map((r) =>
              r ? t(`route.${r.replace("/", ".")}`) : ""
            )}`}
            render={(props) => {
              const RouteComponent = route.component;
              const nextProps = {
                ...props,
                match: {
                  ...props.match,
                  params: {
                    ...props.match.params,
                  },
                  routeKeys,
                },
              };
              return <RouteComponent {...nextProps} routes={route.routes} />;
            }}
          />
        );
      })}
      <Route component={NotFound} />
    </Switch>
  );
};

const App = (props) => {
  const prefixLanguages = AVAILABLE_LANGUAGES.filter(
    (l) => l !== DEFAULT_LANGUAGE
  );

  return (
    <Router>
      <ScrollToTop>
        {/* <Route exact path="/" render={props => {
          const language = i18n.languages[0];
          if (language !== DEFAULT_LANGUAGE) {
            return <Redirect exact strict from="/" to={`/${language}`} />
          }
        }} /> */}
        <Route
          path={`/:locale(${prefixLanguages.join("|")})?`}
          component={Routes}
        />
      </ScrollToTop>
    </Router>
  );
};

export default withTranslation()(App);
