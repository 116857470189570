import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Icon from "../../components/Icon";
import Pricing from "../../components/Pricing";
import PricesEarnings from "../../components/PricesEarnings";
import Trust from "../../components/Trust";

import { Layout, HelmetCanonical } from "../../components";

import "./Pricing.scss";

const PricingView = ({ match: { routeKeys } }) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation();

  return (
    <Layout locale={locale}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("seo.pricing.title")}</title>
        <meta name="robots" content="noinindex,followdex"></meta>
        <html lang={locale} />
      </Helmet>

      <HelmetCanonical routes={routeKeys} />

      <main className="kz-pricing">
        <section className="kz-pricing--intro">
          <h1>{t("pricing.title")}</h1>

          <h2>
            {t("pricing.title_2")}
            <span> {t("pricing.title_3")}</span>
          </h2>

          <PricesEarnings />
        </section>

        <Pricing />

        <section className="kz-pricing--version-custom">
          <div className="kz-pricing--version">
            <Icon value="price-4" />
            <div>
              <p>{t("pricing.pricing_nf")}</p>
              <a
                href="demo"
                className="kz-button kz-button--tools kz-button--flat"
              >
                <span>{t("pricing.special_offer")}</span>
              </a>
            </div>
          </div>
        </section>

        <section className="kz-pricing--earnings-mobile">
          <PricesEarnings />
        </section>

        <section className="kz-pricing--options">
          <h3>{t("pricing.options.title")}</h3>
          <ul>
            <li>
              <Icon value="check-green" />
              <p>
                <strong>{t("pricing.options.backup.label")}</strong>{" "}
                {t("pricing.options.backup.price")}
              </p>
            </li>
            <li>
              <Icon value="check-green" />
              <p>
                <strong>{t("pricing.options.hd.label")}</strong>{" "}
                {t("pricing.options.hd.price")}
              </p>
            </li>
            <li>
              <Icon value="check-green" />
              <p>
                <strong>{t("pricing.options.view_360.label")}</strong>{" "}
                {t("pricing.options.view_360.price")}
              </p>
            </li>
            <li>
              <Icon value="check-green" />
              <p>
                <strong>{t("pricing.options.bo_access.label")}</strong>{" "}
                {t("pricing.options.bo_access.price")}
              </p>
            </li>
          </ul>
        </section>

        <section className="kz-pricing--services">
          <h3>{t("pricing.services.title")}</h3>
          <ul>
            <li>
              <Icon value="check-green" />
              <p>
                <strong>{t("pricing.services.build.label")}</strong>{" "}
                {t("pricing.services.build.price")}
              </p>
            </li>
            <li>
              <Icon value="check-green" />
              <p>
                <strong>{t("pricing.services.formation.label")}</strong>{" "}
                {t("pricing.services.formation.price")}
              </p>
            </li>
            <li>
              <Icon value="check-green" />
              <p>
                <strong>{t("pricing.services.help.label")}</strong>{" "}
                {t("pricing.services.help.price")}
              </p>
            </li>
          </ul>
        </section>

        <Trust />
      </main>
    </Layout>
  );
};

export default PricingView;
