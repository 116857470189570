import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import BusinessNav from "../../../components/BusinessNav";
import Icon from "../../../components/Icon";
import Trust from "../../../components/Trust";
import Prices from "../../../components/Prices";
import Discover from "../../../components/Discover";
import CustomSlider from "../../../components/Slider";
import Examples from "../../../components/Examples";

import "./MaterialDistributor.scss";
import { HelmetCanonical, Layout } from "../../../components";

class MaterialDistributor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      read_more1: false,
    };
  }

  render() {
    const {
      t,
      i18n: { language },
      match: {
        routeKeys,
        params: { locale },
      },
    } = this.props;
    const prefix = locale ? `/${locale}` : "";
    const slides = [
      {
        defaultSrc: "/business/materials/carousel-01.png",
        mobileSrc: "/business/materials/carousel-01-mobile.png",
        alt: t("images.alt.material_distributor.carousel_01"),
      },
      {
        defaultSrc: "/business/materials/carousel-02.png",
        mobileSrc: "/business/materials/carousel-02-mobile.png",
        alt: t("images.alt.material_distributor.carousel_02"),
      },
      {
        defaultSrc: "/business/materials/carousel-03.png",
        mobileSrc: "/business/materials/carousel-03-mobile.png",
        alt: t("images.alt.material_distributor.carousel_03"),
      },
    ];

    const imagesExamples = [
      {
        url: "/material/1_EXT.jpg",
        translationKey: "material.ext_1",
        alt: t("images.alt.material_distributor.example_01"),
      },
      {
        url: "/material/2_ELEC.jpg",
        translationKey: "material.elec_2",
        alt: t("images.alt.material_distributor.example_02"),
      },
      {
        url: "/material/3_SDB.jpg",
        translationKey: "material.sdb_3",
        alt: t("images.alt.material_distributor.example_03"),
      },
      {
        url: "/material/4_PLAN.jpg",
        translationKey: "material.plan_4",
        alt: t("images.alt.material_distributor.example_04"),
      },
    ];

    return (
      <Layout locale={locale}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("seo.business.material_distributor.title")}</title>
          <meta
            name="description"
            content={t("seo.business.material_distributor.description")}
          ></meta>
          <meta name="robots" content="index,follow"></meta>
          <html lang={language} />
        </Helmet>

        <HelmetCanonical routes={routeKeys} />

        <main className="kz-business">
          <section className="kz-business--intro">
            <BusinessNav />

            <Icon value="materials" />

            <h1>
              <strong>
                {t("business.material_distributor.title")}
                <br />
              </strong>
              {t("business.material_distributor.title_2")}
            </h1>

            <Link
              to={`${prefix}/demo`}
              className="kz-button kz-button--tools kz-button--flat"
            >
              <span>{t("business.material_distributor.visibility")}</span>
            </Link>
            <span>{t("business.material_distributor.desc_1")}</span>

            <CustomSlider slides={slides} />
          </section>

          <section className="kz-business--features">
            <div className="kz-business--container">
              <div className="kz-business--content kz-business--content-description">
                <img
                  src={
                    require("../../../assets/img/business/materials/features-01-mobile.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.material_distributor.feature_01")}
                />
                <h2>{t("business.material_distributor.card_1_title")}</h2>
                <p>{t("business.material_distributor.card_1_desc_1")}</p>
                <p>
                  {t("business.material_distributor.card_1_desc_2")}
                  <br />
                  {t("business.material_distributor.card_1_desc_1")}
                </p>
                <Link
                  to={`${prefix}/demo`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("contact_us")}</span>
                </Link>
              </div>
              <div className="kz-business--content kz-business--content-visuel">
                <img
                  src={
                    require("../../../assets/img/business/materials/features-01.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.material_distributor.feature_01")}
                />
              </div>
            </div>

            <div className="kz-business--container">
              <div className="kz-business--content kz-business--content-visuel">
                <img
                  src={
                    require("../../../assets/img/business/materials/features-02.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.material_distributor.feature_02")}
                />
              </div>
              <div
                className={`kz-business--content kz-business--content-description ${
                  this.state.read_more1 ? "kz-deployed" : null
                }`}
              >
                <img
                  src={
                    require("../../../assets/img/business/materials/features-02-mobile.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.material_distributor.feature_02")}
                />
                <h2>{t("business.material_distributor.card_2_title")}</h2>
                <p>{t("business.material_distributor.card_2_desc_1")}</p>
                <p>
                  {t("business.material_distributor.card_2_desc_2")}
                  <span className="kz-business--content-hidden">
                    <br />
                    <br />
                    {t("business.material_distributor.card_2_desc_3")}
                  </span>
                </p>
                <p
                  onClick={() => this.setState({ read_more1: true })}
                  className="kz-business--content-more"
                >
                  {t("business.material_distributor.read_more")}
                </p>
                <Link
                  to={`${prefix}/demo`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("contact_us")}</span>
                </Link>
              </div>
            </div>

            <div className="kz-business--container">
              <div className="kz-business--content kz-business--content-description">
                <img
                  src={
                    require("../../../assets/img/business/materials/features-03-mobile.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.material_distributor.feature_03")}
                />
                <h2>{t("business.material_distributor.card_3_title")}</h2>
                <p>
                  {t("business.material_distributor.card_3_desc_1")}
                  <br />
                  {t("business.material_distributor.card_3_desc_2")}
                </p>
                <Link
                  to={`${prefix}/demo`}
                  className="kz-button kz-button--tools kz-button--flat"
                >
                  <span>{t("contact_us")}</span>
                </Link>
              </div>
              <div className="kz-business--content kz-business--content-visuel">
                <img
                  src={
                    require("../../../assets/img/business/materials/features-03.png")
                      .default
                  }
                  loading="lazy"
                  alt={t("images.alt.material_distributor.feature_03")}
                />
              </div>
            </div>
          </section>

          <Examples
            title={"examples.material"}
            imagesExamples={imagesExamples}
          />

          <Prices
            title={t("business.material_distributor.stand_out.title")}
            description={t("business.material_distributor.stand_out.desc")}
          />

          <Trust />

          <Discover />
        </main>
      </Layout>
    );
  }
}

export default withTranslation()(MaterialDistributor);
