import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { withRouter } from "react-router"
import Icon from '../Icon'

import './Prices.scss'

class Prices extends Component {
  render() {
    const { t, match: { params: { locale } } } = this.props;
    const prefix = locale ? `/${locale}` : ''
    return (
      <section className="kz-prices">
        <Icon value="logo-big" />
        <h3>{this.props.title}</h3>
        <p>{this.props.description}</p>
        <Link to={`${prefix}/${t('route.pricing')}`} className="kz-button kz-button--tools kz-button--flat"><span>{t('button.pricing')}</span></Link>
      </section>
    )
  }
}

export default withRouter(withTranslation()(Prices))
