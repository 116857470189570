import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "./Trust.scss";

class Trust extends Component {
  render() {
    const { t } = this.props;

    return (
      <section className="kz-trust">
        <h2>{t("trust.title")}</h2>
        <ul>
          <li>
            <img
              src={
                require("../../assets/img/trust/constructeurdemaison.png")
                  .default
              }
              loading="lazy"
              alt={t("images.alt.trust.home_builder")}
            />
          </li>
          <li>
            <img
              src={require("../../assets/img/trust/autourdebb.png").default}
              loading="lazy"
              alt={t("images.alt.trust.autourdebb")}
            />
          </li>
          <li>
            <img
              src={require("../../assets/img/trust/quotatis.png").default}
              loading="lazy"
              alt={t("images.alt.trust.quotatis")}
            />
          </li>
          <li>
            <img
              src={require("../../assets/img/trust/leroymerlin.png").default}
              loading="lazy"
              alt={t("images.alt.trust.leroymerlin")}
            />
          </li>
          <li>
            <img
              src={require("../../assets/img/trust/lecubefrancais.png").default}
              loading="lazy"
              alt={t("images.alt.trust.lecubefrancais")}
            />
          </li>
        </ul>
      </section>
    );
  }
}

export default withTranslation()(Trust);
