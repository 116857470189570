import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { AVAILABLE_LANGUAGES } from "../../config";
import { getTranslatedRoute } from "../../i18n";

const HelmetCanonical = ({ routes }) => {
  const { t, i18n } = useTranslation();
  const domain = process.env.REACT_APP_SITE_URL;
  return (
    <Helmet>
      <link
        rel="canonical"
        href={`${domain}${getTranslatedRoute(
          t,
          i18n,
          routes[0],
          i18n.language
        )}`}
      />
      {AVAILABLE_LANGUAGES.map((lng) => (
        <link
          key={`canonical.${lng}`}
          rel="alternate"
          href={`${domain}${getTranslatedRoute(t, i18n, routes[0], lng)}`}
          hreflang={lng}
        />
      ))}
    </Helmet>
  );
};

export default HelmetCanonical;
