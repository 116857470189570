import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Layout, HelmetCanonical, Expire } from "../../components";
import { withTranslation } from "react-i18next";

import Icon from "../../components/Icon";
import Trust from "../../components/Trust";

import "./Contact.scss";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        company: "",
        country: "france",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        business: props.t("contact.home_builder"),
        message: ""
      },
      confirmation: false,
      error: false
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
    this.handleLastnameChange = this.handleLastnameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleBusinessChange = this.handleBusinessChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
  }

  handleCompanyChange = e =>
    this.setState({
      error: false,
      confirmation: false,
      formData: { ...this.state.formData, company: e.target.value }
    });
  handleCountryChange = e =>
    this.setState({
      error: false,
      confirmation: false,
      formData: { ...this.state.formData, country: e.target.value }
    });
  handleFirstnameChange = e =>
    this.setState({
      error: false,
      confirmation: false,
      formData: { ...this.state.formData, firstname: e.target.value }
    });
  handleLastnameChange = e =>
    this.setState({
      error: false,
      confirmation: false,
      formData: { ...this.state.formData, lastname: e.target.value }
    });
  handleEmailChange = e =>
    this.setState({
      error: false,
      confirmation: false,
      formData: { ...this.state.formData, email: e.target.value }
    });
  handlePhoneChange = e =>
    this.setState({
      error: false,
      confirmation: false,
      formData: { ...this.state.formData, phone: e.target.value }
    });
  handleBusinessChange = e =>
    this.setState({
      error: false,
      confirmation: false,
      formData: { ...this.state.formData, business: e.target.value }
    });
  handleMessageChange = e =>
    this.setState({
      error: false,
      confirmation: false,
      formData: { ...this.state.formData, message: e.target.value }
    });

  submitForm = e => {
    e.preventDefault();

    fetch(process.env.REACT_APP_GCF_SEND_EMAIL_BY_REQUEST, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          template: "contact",
          message: {
            from: this.state.formData.email,
            to: process.env.REACT_APP_CONTACT_EMAIL,
          },
          params: {
            locale: "fr",
            contact: this.state.formData,
          },
        }
      }),
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ confirmation: true });
        this.clearForm();
      })
      .catch(err => {
        this.setState({ error: true });
        this.clearForm();
      });
  };

  clearForm() {
    this.refs.companyInput.value = "";
    this.refs.firstnameInput.value = "";
    this.refs.lastnameInput.value = "";
    this.refs.emailInput.value = "";
    this.refs.phoneInput.value = "";
    this.refs.messageInput.value = "";
  }

  render() {
    const {
      t,
      i18n: { language: locale },
      match: { routeKeys }
    } = this.props;

    return (
      <Layout locale={locale}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("seo.contact.title")}</title>
          <meta name="robots" content="index,follow"></meta>
          <html lang={locale} />
        </Helmet>

        <HelmetCanonical routes={routeKeys} />

        <main className="kz-contact">
          <div className="kz-contact--intro">
            <h1>{t("contact.title")}</h1>
          </div>

          <div className="kz-contact--form">
            <div className="kz-contact--form-container">
              <form id="kz-contact--form-demo" onSubmit={this.submitForm}>
                <label className="kz-contact--form-half">
                  <span>{t("contact.company")}</span>
                  <input
                    ref="companyInput"
                    type="text"
                    onChange={this.handleCompanyChange}
                  />
                </label>

                <label className="kz-contact--form-half">
                  <span>{t("contact.country")}</span>
                  <select
                    defaultValue="france"
                    required
                    onChange={this.handleCountryChange}
                  >
                    <option value="france">France</option>
                    <option value="belgique">Belgique</option>
                    <option value="allemagne">Allemagne</option>
                    <option value="...">...</option>
                  </select>
                </label>

                <label className="kz-contact--form-half">
                  <span>{t("contact.first_name")}</span>
                  <input
                    ref="firstnameInput"
                    type="text"
                    required
                    onChange={this.handleFirstnameChange}
                  />
                </label>

                <label className="kz-contact--form-half">
                  <span>{t("contact.last_name")}</span>
                  <input
                    ref="lastnameInput"
                    type="text"
                    required
                    onChange={this.handleLastnameChange}
                  />
                </label>

                <label className="kz-contact--form-half">
                  <span>{t("contact.email")}</span>
                  <input
                    ref="emailInput"
                    type="email"
                    required
                    onChange={this.handleEmailChange}
                  />
                </label>

                <label className="kz-contact--form-half">
                  <span>{t("contact.phone")}</span>
                  <input
                    ref="phoneInput"
                    type="tel"
                    pattern="^[0-9-+s()]*$"
                    required
                    onChange={this.handlePhoneChange}
                  />
                </label>

                <label className="kz-contact--form-half">
                  <span>{t("contact.business")}</span>
                  <select
                    defaultValue={this.state.business}
                    onChange={this.handleBusinessChange}
                  >
                    <option value={this.state.business}>
                      {t("contact.home_builder")}
                    </option>
                    <option value={t("contact.furniture_distributor")}>
                      {t("contact.furniture_distributor")}
                    </option>
                    <option value={t("contact.material_distributor")}>
                      {t("contact.material_distributor")}
                    </option>
                    <option value={t("contact.other")}>
                      {t("contact.other")}
                    </option>
                  </select>
                </label>

                <label className="kz-contact--form-full">
                  <span>{t("contact.message")}</span>
                  <textarea
                    ref="messageInput"
                    required
                    onChange={this.handleMessageChange}
                  ></textarea>
                </label>

                <div className="kz-contact--form-full kz-contact--form-center">
                  <input
                    type="submit"
                    value={t("contact.contact_button")}
                    className="kz-button kz-button--tools kz-button--flat"
                  />
                </div>

                <p className="kz-contact--form-full kz-contact-mentions">
                  {t("contact.contact_mentions")}
                </p>
              </form>
              {this.state.confirmation ? (
                <Expire delay={3000}>
                  <p className="kz-contact--notification kz-contact--notification-confirmation">
                    <Icon value="check-white" /> {t("contact.confirmation")}
                  </p>
                </Expire>
              ) : null}
              {this.state.error ? (
                <Expire delay={3000}>
                  <p className="kz-contact--notification kz-contact--notification-error">
                    {t("contact.error")}
                  </p>
                </Expire>
              ) : null}
            </div>
          </div>

          <Trust />
        </main>
      </Layout>
    );
  }
}

export default withTranslation()(Contact);
