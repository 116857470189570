import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import "./BusinessNav.scss";

class BusinessNav extends Component {
  render() {
    const {
      t,
      match: {
        params: { locale },
      },
    } = this.props;

    const prefix = locale ? `/${locale}` : "";

    return (
      <ul className="kz-business-nav">
        <li>
          <NavLink to={`${prefix}/${t("route.individual-home-builders")}`}>
            {t("navbar.menu.business.home_builder")}
          </NavLink>
        </li>
        <li>
          <NavLink to={`${prefix}/${t("route.furniture-distributor")}`}>
            {t("navbar.menu.business.furniture_distributor")}
          </NavLink>
        </li>
        <li>
          <NavLink to={`${prefix}/${t("route.building-material-distributor")}`}>
            {t("navbar.menu.business.material_distributor")}
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default withRouter(withTranslation()(BusinessNav));
